import React, {Component} from 'react';

import OverlayHeader from './overlay-views/OverlayHeader';
import OverlayContent from './overlay-views/OverlayContent';

class Overlay extends Component {
  render() {
    const {element} = this.props;
    const {layer, name, subname} = element;

    return (
      <>
        <OverlayHeader layer={layer} name={name} subname={subname}/>
        <OverlayContent layer={layer} content={element}/>
      </>
    )
  }
}

export default Overlay;