import React, {Component} from 'react';
import LayersContext from './LayersContext';
import * as Styled from './App.styles'
import {Slide, ToastContainer} from "react-toastify";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-tippy/dist/tippy.css'
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/header/Header';
import Map from './components/map/Map';
import SplashScreen from './components/splash-screen/SplashScreen';
import {debounce} from "./utils";

class App extends Component {
  state = {
    selectedGroup: undefined,
    activeGroups: {
      PARKINGS: false,
      PARKING_METERS: false,
      VEHICLES: false,
      STOPS: false,
      CAMERAS: false,
      VMS: false,
      SENSORS: false,
      EVENTS: false,
    }
  };

  componentDidMount() {
    this.handleWindowSizeChange();
  }

  componentWillMount() {
    this.handleResize = debounce(this.handleWindowSizeChange.bind(this), 100);

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleWindowSizeChange = () => this.setState({ isMobile: window.innerWidth <= 480 });

  onLayerSelected(e) {
    this.setState({
      selectedGroup: e,
      activeGroups: {
        ...this.state.activeGroups,
        [e]: !this.state.activeGroups[e]
      }
    });
  }

  render() {
    return (
      <LayersContext.Provider
        value={{
          selectedGroup: this.state.selectedGroup,
          layers: this.state.activeGroups,
          isMobile: this.state.isMobile,
          onLayerSelected: this.onLayerSelected.bind(this)
        }}
      >
        <Styled.Container>
          <SplashScreen/>
          <Header />
          <Map />
          <ToastContainer transition={Slide}/>
        </Styled.Container>
      </LayersContext.Provider>
    );
  }
}

export default App;
