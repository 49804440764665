import {Icon, Style} from 'ol/style.js';
import ParkingMarker from '../../assets/gfx/markers/parking.svg'
import ParkingLowLoadMarker from '../../assets/gfx/markers/parking-empty.svg'
import ParkingMediumLoadMarker from '../../assets/gfx/markers/parking-loaded.svg'
import ParkingHighLoadMarker from '../../assets/gfx/markers/parking-full.svg'
import ParkingSelectedMarker from '../../assets/gfx/markers/parking-selected.svg'
import {Fill, Stroke} from "ol/style";

export default {
  marker: {
    default: new Style({
      image: new Icon({
        anchor: [0.5, 60],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixel',
        src: ParkingMarker
      })
    }),
    empty: new Style({
      image: new Icon({
        anchor: [0.5, 60],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixel',
        src: ParkingLowLoadMarker
      })
    }),
    loaded: new Style({
      image: new Icon({
        anchor: [0.5, 60],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixel',
        src: ParkingMediumLoadMarker
      })
    }),
    full: new Style({
      image: new Icon({
        anchor: [0.5, 60],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixel',
        src: ParkingHighLoadMarker
      })
    }),
    hovered: new Style({
      image: new Icon({
        anchor: [0.5, 60],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixel',
        src: ParkingMarker,
        color: '#f8f8f8'
      })
    }),
    selected: new Style({
      image: new Icon({
        anchor: [0.5, 60],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixel',
        src: ParkingSelectedMarker
      })
    })
  },
  polygon: {
    default: new Style({}),
    empty: new Style({
      stroke: new Stroke({
        color: '#39b74d',
        width: 1,
        lineDash: [3, 3]
      }),
      fill: new Fill({
        color: 'rgba(106,183,85,0.3)'
      })
    }),
    loaded: new Style({
      stroke: new Stroke({
        color: '#f8c348',
        width: 1,
        lineDash: [3, 3]
      }),
      fill: new Fill({
        color: 'rgba(248,195,72,0.3)'
      })
    }),
    full: new Style({
      stroke: new Stroke({
        color: '#e16d6d',
        width: 1,
        lineDash: [3, 3]
      }),
      fill: new Fill({
        color: 'rgba(255,109,109,0.3)'
      })
    }),
    hovered: new Style({
      stroke: new Stroke({
        color: '#57729b',
        width: 1,
        lineDash: [3, 3]
      }),
      fill: new Fill({
        color: 'rgba(87,114,155,0.3)'
      })
    }),
    selected: new Style({
      stroke: new Stroke({
        color: '#57729b',
        width: 1,
        lineDash: [3, 3]
      }),
      fill: new Fill({
        color: 'rgba(87,114,155,0.3)'
      })
    })
  }
}
