import React from 'react';
import * as Styled from "./LineNumbers.styles";

const LineNumbers = (props) => {
  const { selectedLine, setSelectedLine, lines } = props;

  if (lines.length === 0) return (
    <Styled.NoLinesNotification>
      Brak rozkładu jazdy
    </Styled.NoLinesNotification>
  );

  return (
    <Styled.LineNumbersContainer>
      {lines.map(line => <Styled.LineNumber key={line.number}
                                            isActive={selectedLine ? selectedLine.number === line.number : false}
                                            onClick={() => setSelectedLine(line)}>{line.number}</Styled.LineNumber>)}
    </Styled.LineNumbersContainer>
  );
};

export default LineNumbers;