export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "Wydarzenie 1"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33509540557861,
          50.4772264412505
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Wydarzenie 2"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331876754760742,
          50.475751641752055
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Wydarzenie 3"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335996627807617,
          50.47274044990451
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Wydarzenie 4"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33343243598938,
          50.47123136778738
        ]
      }
    }
  ]
}