export default {
  "type": "FeatureCollection",
  "features": [
  {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [
            17.338839769363403,
            50.47192787321152
          ],
          [
            17.336608171463013,
            50.47073971072193
          ],
          [
            17.336586713790894,
            50.47064411014324
          ],
          [
            17.338582277297974,
            50.46834963826359
          ],
          [
            17.33941912651062,
            50.46839061195212
          ],
          [
            17.33944058418274,
            50.46881400465456
          ],
          [
            17.33940839767456,
            50.46935348341149
          ],
          [
            17.33915090560913,
            50.46968809372479
          ],
          [
            17.340556383132935,
            50.47017976172576
          ],
          [
            17.34015941619873,
            50.47062020996836
          ],
          [
            17.339816093444824,
            50.471098211170315
          ],
          [
            17.338839769363403,
            50.47192787321152
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [
            17.333807945251465,
            50.470889939812125
          ],
          [
            17.332107424736023,
            50.46884473462251
          ],
          [
            17.332112789154053,
            50.46868767013213
          ],
          [
            17.332268357276917,
            50.46852377619463
          ],
          [
            17.333078384399414,
            50.46819257212865
          ],
          [
            17.3344087600708,
            50.46803550547195
          ],
          [
            17.335503101348877,
            50.46805940695337
          ],
          [
            17.33646869659424,
            50.46816525622583
          ],
          [
            17.336844205856323,
            50.46827451974239
          ],
          [
            17.3369300365448,
            50.46835646721415
          ],
          [
            17.33695149421692,
            50.46846573028887
          ],
          [
            17.337021231651306,
            50.46855792081178
          ],
          [
            17.337895631790158,
            50.46886863569486
          ],
          [
            17.33642041683197,
            50.47062020996836
          ],
          [
            17.336259484291077,
            50.47064411014324
          ],
          [
            17.33519196510315,
            50.47041193650454
          ],
          [
            17.334998846054077,
            50.47043583678471
          ],
          [
            17.334505319595337,
            50.470657767380615
          ],
          [
            17.333807945251465,
            50.470889939812125
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [
            17.33367919921875,
            50.4708967683958
          ],
          [
            17.329859733581543,
            50.47219418139733
          ],
          [
            17.329516410827637,
            50.472583398355184
          ],
          [
            17.32945203781128,
            50.4727336215871
          ],
          [
            17.329634428024292,
            50.47398318724461
          ],
          [
            17.330138683319092,
            50.47452260702146
          ],
          [
            17.330052852630615,
            50.47461819975759
          ],
          [
            17.32921600341797,
            50.474966428804606
          ],
          [
            17.329033613204956,
            50.47492546081456
          ],
          [
            17.327327728271484,
            50.47301358179113
          ],
          [
            17.327274084091187,
            50.47256291333202
          ],
          [
            17.32741355895996,
            50.47233757749145
          ],
          [
            17.330492734909054,
            50.47017293303855
          ],
          [
            17.33195185661316,
            50.469626634865186
          ],
          [
            17.332541942596436,
            50.46955834714971
          ],
          [
            17.33367919921875,
            50.4708967683958
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [
            17.337616682052612,
            50.47900842925485
          ],
          [
            17.334119081497192,
            50.47719230289344
          ],
          [
            17.332037687301636,
            50.475710674442524
          ],
          [
            17.331855297088623,
            50.4754375581382
          ],
          [
            17.331007719039917,
            50.47453626313845
          ],
          [
            17.331061363220215,
            50.474440670236625
          ],
          [
            17.331029176712036,
            50.4743450771415
          ],
          [
            17.33091115951538,
            50.47424948385305
          ],
          [
            17.33070731163025,
            50.47421534334608
          ],
          [
            17.33041763305664,
            50.47366909188093
          ],
          [
            17.330353260040283,
            50.47299309695437
          ],
          [
            17.330385446548462,
            50.472719964949405
          ],
          [
            17.330321073532104,
            50.47246048808309
          ],
          [
            17.3304283618927,
            50.472255636919485
          ],
          [
            17.330621480941772,
            50.47212589739014
          ],
          [
            17.334569692611694,
            50.470787510938756
          ],
          [
            17.33517050743103,
            50.47054850937127
          ],
          [
            17.335352897644043,
            50.47056216663623
          ],
          [
            17.33617901802063,
            50.47076019653504
          ],
          [
            17.336297035217285,
            50.47087628264182
          ],
          [
            17.336490154266357,
            50.470862625467596
          ],
          [
            17.338753938674927,
            50.47206444169934
          ],
          [
            17.340803146362305,
            50.47439970179095
          ],
          [
            17.340985536575317,
            50.47454309119547
          ],
          [
            17.341328859329224,
            50.474672824091456
          ],
          [
            17.341339588165283,
            50.474829868699736
          ],
          [
            17.34039545059204,
            50.47654366942491
          ],
          [
            17.340191602706906,
            50.4767621574821
          ],
          [
            17.337616682052612,
            50.47900842925485
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [
            17.337525486946106,
            50.479131322502944
          ],
          [
            17.336549162864685,
            50.479960843570055
          ],
          [
            17.3343551158905,
            50.47872509082635
          ],
          [
            17.334237098693848,
            50.47861243772628
          ],
          [
            17.330519556999207,
            50.476052067603916
          ],
          [
            17.32933938503265,
            50.47512688642375
          ],
          [
            17.330487370491028,
            50.474665996053176
          ],
          [
            17.3305732011795,
            50.47469672221767
          ],
          [
            17.33143150806427,
            50.47559460020598
          ],
          [
            17.331812381744385,
            50.475751641752055
          ],
          [
            17.33240783214569,
            50.476256902317324
          ],
          [
            17.33392596244812,
            50.47725375191838
          ],
          [
            17.33517050743103,
            50.47792285835272
          ],
          [
            17.336592078208923,
            50.47867388490505
          ],
          [
            17.337525486946106,
            50.479131322502944
          ]
        ]
      ]
    }
  }
]
}