import { responseMiddleware } from './response-middleware';
import PATHS from '../constants/paths';
import EmptyDataset from "../constants/mock-data/features/empty-dataset";

export default {
  getParkings,
  getParkingPlaces,
}

function getParkings() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/parking`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}

function getParkingPlaces() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/parking/place`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}
