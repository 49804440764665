import OlMap from 'ol/Map';

import {createView} from './view';
import {createMapTilerLayer} from "./layers";

export const initializeMap = (props) => {
  return new OlMap({
    target: props.target,
    view: createView(props.extent),
    interactions: props.interactions,
    layers: [
      createMapTilerLayer(),
      ...props.layers
    ],
    loadTilesWhileAnimating: true
  });
};
