import styled from 'styled-components';

export const Image = styled.img.attrs({
  alt: 'camera'
})`
  height: 100%;
  width: 100%;
  object-fit: fill;
`;

export const FullscreenButtonContainer = styled.div`
  cursor: pointer;
  height: 16px;
  width: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  
  :hover {
    opacity: 0.7;
  }
`;

export const FullscreenImageContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  padding: 1rem;
  width: calc(100vw - 2rem);
  height: calc(100% - 2rem - 60px);
  background: rgba(0, 0, 0, 0.8);
`;

export const FullscreenImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
