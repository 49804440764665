import React, {Component} from 'react';
import * as Styled from './ParkingSpotTooltip.styles';
import Button from '../../../common/Button';
import {toLonLat} from 'ol/proj';

// openlayers overlay stopEvent blocks onclick, thats a workaround for ol closing the overlay on click
const convertToClick = (e) => {
  const evt = new MouseEvent('click', { bubbles: true })
  evt.stopPropagation = () => {};
  e.target.dispatchEvent(evt)
};

class ParkingSpotTooltip extends Component {
  navigateTo() {
    const destination = toLonLat(this.props.content.coordinates);
    const url = `https://www.google.com/maps/dir/?api=1&destination=${destination[1]},${destination[0]}`;
    const anchor = document.createElement("a");

    anchor.href = url;
    anchor.target = '_blank';

    document.body.appendChild(anchor);
    anchor.click();
  }

  render() {
    return (
      <Styled.Container onMouseUp={convertToClick}>
        <Styled.Header>Miejsce postojowe</Styled.Header>
        <Styled.SpotsCount>{this.props.content.displayName}</Styled.SpotsCount>
        <Button onClick={() => this.navigateTo()} text={"Nawiguj do miejsca"}></Button>
      </Styled.Container>
    )
  }
}

export default ParkingSpotTooltip;