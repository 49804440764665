import React, {Component} from 'react';
import * as Styled from './ParkingMeter.styles';

class ParkingMeter extends Component {
  renderFees() {
    if (this.props.fees) {
      return this.props.fees.map((fee, index) => (
        <Styled.Row key={index}>
          <Styled.RowName>{fee.name}</Styled.RowName>
          <Styled.RowValue>{fee.value}</Styled.RowValue>
        </Styled.Row>
      ))
    }
  }

  renderValidityHours() {
    if (this.props.validityHours) {
      return (
        <Styled.Container>
          <Styled.ContainerHeader>
            Godziny funkcjonowania
          </Styled.ContainerHeader>
          <Styled.ContainerTable>
            {
              this.props.validityHours.map((hour, index) => (
                <Styled.Row key={index}>
                  <Styled.RowName>{hour.name}</Styled.RowName>
                  <Styled.RowValue>{hour.value}</Styled.RowValue>
                </Styled.Row>
              ))
            }
          </Styled.ContainerTable>
        </Styled.Container>
      )
    }
  }

  render() {
    return (
      <>
        <Styled.Container>
          <Styled.ContainerHeader>
            Opłaty
          </Styled.ContainerHeader>
          <Styled.ContainerTable>
            {this.renderFees()}
          </Styled.ContainerTable>
        </Styled.Container>
        {this.renderValidityHours()}
      </>
    )
  }
}

export default ParkingMeter;