import LAYERS from '../../../constants/layers';
import {checkVisibility} from './visibility-threshold';
import {
  AirlyStylingFunction,
  DefaultStylingFunction,
  ParkingSpotsStylingFunction,
  ParkingStylingFunction
} from './layer-style';

import SourceVector from 'ol/source/Vector';
import LayerVector from 'ol/layer/Vector';
import LayerTile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import LAYER_SYMBOLS from "../../../constants/layer-symbols";


export const setLayersVisibility = ({ layers, menuItems, zoom }) => {
  Object.keys(layers).filter(layer => layers[layer] === true);

  layers.forEach(layer => {
    const symbol = layer.get('groupSymbol');
    const hasBreakpoint = layer.get('visibilityBreakpoint').hasBreakpoint;
    const isActive = menuItems[symbol];

    if (!hasBreakpoint) layer.setVisible(isActive);
    else if (isActive) layer.setVisible(checkVisibility(layer, zoom));
    else layer.setVisible(false);
  })
};

export const initializeLayers = () => {
  const layers = LAYERS.map(layer =>
    layer.mapLayers.map(mapLayer => {
      return _initializeLayer({
        groupSymbol: layer.symbol,
        symbol: mapLayer.mapSymbol,
        type: mapLayer.geometryType,
        style: mapLayer.style,
        isInteractive: mapLayer.interactive,
        visibilityBreakpoint: mapLayer.visibilityBreakpoint,
        zIndex: mapLayer.zIndex
      })
    })
  );

  return layers.flat();
};

const _initializeLayer = ({ groupSymbol, symbol, type, style, isInteractive, visibilityBreakpoint, zIndex }) => {
  const layer = _createVectorLayer({ type, style, symbol, zIndex });

  layer.setProperties({
    symbol,
    groupSymbol,
    style,
    geometryType: type,
    isInteractive,
    visibilityBreakpoint
  });

  return layer;
};

const _createVectorLayer = ({ type, style, symbol, zIndex }) => {
  const source = new SourceVector();
  const layer = new LayerVector({
    renderMode: 'image',
    source,
    style: (feature, resolution) => {
      switch (symbol) {
        case LAYER_SYMBOLS.PARKINGS_POINT:
          return ParkingSpotsStylingFunction({ feature, resolution, style });
        case LAYER_SYMBOLS.PARKINGS_POLYGON:
          return ParkingStylingFunction({ feature, resolution, style });
        case LAYER_SYMBOLS.AIRLY_CLOUD:
          return AirlyStylingFunction({ feature, resolution, style });
        default:
          return DefaultStylingFunction({ feature, resolution, style });
      }
    },
    visible: false,
    zIndex: zIndex || _defaultLayerZIndex(type)
  });

  return layer;
};

const _defaultLayerZIndex = (geometryType) => {
  switch (geometryType) {
    case 'Point':
      return 5;
    case 'LineString':
      return 3;
    case 'Polygon':
      return 1;
    case 'Heatmap':
      return 1;
    default:
      return 1;
  }
};

export const findFeature = (layer, featureId) => {
  return layer.getSource().getFeatureById(featureId);
};

export const createOSMLayer = () => {
  return new LayerTile({
    source: new OSM()
  });
};

export const createMapTilerLayer = () => {
  return new LayerTile({
    zIndex: 1,
    source: new XYZ({
      tileSize: 512,
      url: 'https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=KKaQ4H6cpTYs3RU7fHeN'
    })
  })
};

export const createCartoOSMLayer = () => {
  return new LayerTile({
    zIndex: 1,
    source: new XYZ({
      url: 'https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
    })
  })
};

export const createBWMapnikOSMLayer = () => {
  return new LayerTile({
    source: new XYZ({
      url: 'https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png'
    })
  })
};
