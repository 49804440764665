import React, {Component} from 'react';
import * as Styled from './Sensor.styles';
import * as Icons from './icons';
import {calculateYDomain, transformData, colorRange} from "./helpers";
import XYPlot from "react-vis/es/plot/xy-plot";
import {makeVisFlexible} from "react-vis/es/make-vis-flexible";
import VerticalBarSeries from "react-vis/es/plot/series/vertical-bar-series";
import YAxis from "react-vis/es/plot/axis/y-axis";
import Hint from "react-vis/es/plot/hint";

class Sensor extends Component {
  state = {
    forecastTooltipValue: undefined,
    forecastPlotData: undefined,
    forecastPlotDomain: undefined,
    historyTooltipValue: undefined,
    historyPlotData: undefined,
    historyPlotDomain: undefined
  };

  _onMouseLeave = (tooltipValueName) => {
    this.setState({ [tooltipValueName]: undefined });
  };

  _onNearestX = (tooltipValueName, value) => {
    if (!this.state[tooltipValueName] || this.state[tooltipValueName].x !== value.x)
      this.setState({ [tooltipValueName]: value });
  };

  componentDidMount() {
    const { forecast, history } = this.props;

    if (forecast) {
      const forecastPlotData = transformData(forecast, 'caqi');

      this.setState({
        forecastPlotDomain: calculateYDomain(forecastPlotData),
        forecastPlotData: forecastPlotData,
      });
    }

    if (history) {
      const historyPlotData = transformData(history, 'caqi');

      this.setState({
        historyPlotDomain: calculateYDomain(historyPlotData),
        historyPlotData: historyPlotData
      });
    }

  }

  componentDidUpdate(prevProps) {
    const { id, forecast, history } = this.props;

    if (prevProps.id !== id) {
      if (forecast) {
        const forecastPlotData = transformData(forecast, 'caqi');

        this.setState({
          forecastPlotDomain: calculateYDomain(forecastPlotData),
          forecastPlotData: forecastPlotData,
        });
      }

      if (history) {
        const historyPlotData = transformData(history, 'caqi');

        this.setState({
          historyPlotDomain: calculateYDomain(historyPlotData),
          historyPlotData: historyPlotData
        });
      }
    }
  }

  renderCAQIMessage() {
    const {level, caqi, description, advice} = this.props;

    return (
      <Styled.CAQIMessageItem level={level}>
        <Styled.IndexContainer>
          <Styled.CAQIIndex>
            <Styled.CAQIValue>{Math.round(caqi)}</Styled.CAQIValue>
            <Styled.CAQISymbol>CAQI</Styled.CAQISymbol>
          </Styled.CAQIIndex>
        </Styled.IndexContainer>
        <Styled.MessageContainer>
          <Styled.MessageContent>
            <span>{description}</span>
            <span>{advice}</span>
          </Styled.MessageContent>
        </Styled.MessageContainer>
      </Styled.CAQIMessageItem>
    )
  }

  renderTemperature() {
    const {temperature} = this.props;

    return (
      <Styled.MeasurementItem>
        <Styled.MeasurementIconContainer>
          <Styled.MeasurementIcon src={Icons.TemperatureIcon}/>
        </Styled.MeasurementIconContainer>
        <Styled.MeasurementDataContainer>
          {Math.round(temperature)} &deg;C
        </Styled.MeasurementDataContainer>
      </Styled.MeasurementItem>
    )
  }

  renderHumidity() {
    const {humidity} = this.props;

    return (
      <Styled.MeasurementItem>
        <Styled.MeasurementIconContainer>
          <Styled.MeasurementIcon src={Icons.HumidityIcon}/>
        </Styled.MeasurementIconContainer>
        <Styled.MeasurementDataContainer>
          {Math.round(humidity)} %
        </Styled.MeasurementDataContainer>
      </Styled.MeasurementItem>
    )
  }

  renderAirPressure() {
    const {pressure} = this.props;

    return (
      <Styled.MeasurementItem>
        <Styled.MeasurementIconContainer>
          <Styled.MeasurementIcon src={Icons.AirPressureIcon}/>
        </Styled.MeasurementIconContainer>
        <Styled.MeasurementDataContainer>
          <span>{Math.round(pressure)} hPa</span>
        </Styled.MeasurementDataContainer>
      </Styled.MeasurementItem>
    )
  }

  renderPMSymbol() {
    return (<>&micro;g/m<sup>3</sup></>);
  }

  renderPM10() {
    const {standards, pm10} = this.props;
    const standard = standards ? standards.find(standard => standard.pollutant === 'PM10') : undefined;

    return (
      <Styled.MeasurementItem>
        <Styled.MeasurementIconContainer>
          <Styled.MeasurementIcon src={Icons.PPMIcon}/>
          <Styled.MeasurementLabel>PM10</Styled.MeasurementLabel>
        </Styled.MeasurementIconContainer>
        <Styled.MeasurementDataContainer>
          <span>{Math.round(pm10)} {this.renderPMSymbol()}</span>
          {standard ? <Styled.Standards>({Math.round(standard.percent)}%)</Styled.Standards> : null}
        </Styled.MeasurementDataContainer>
      </Styled.MeasurementItem>
    )
  }

  renderPM25() {
    const {standards, pm25} = this.props;
    const standard = standards ? standards.find(standard => standard.pollutant === 'PM25') : undefined;

    return (
      <Styled.MeasurementItem>
        <Styled.MeasurementIconContainer>
          <Styled.MeasurementIcon src={Icons.PPMIcon}/>
          <Styled.MeasurementLabel>PM2,5</Styled.MeasurementLabel>
        </Styled.MeasurementIconContainer>
        <Styled.MeasurementDataContainer>
          <span>{Math.round(pm25)} {this.renderPMSymbol()}</span>
          {standard ? <Styled.Standards>({Math.round(standard.percent)}%)</Styled.Standards> : null}
        </Styled.MeasurementDataContainer>
      </Styled.MeasurementItem>
    )
  }

  renderPM1() {
    const {pm1} = this.props;

    return (
      <Styled.MeasurementItem>
        <Styled.MeasurementIconContainer>
          <Styled.MeasurementIcon src={Icons.PPMIcon}/>
          <Styled.MeasurementLabel>PM1</Styled.MeasurementLabel>
        </Styled.MeasurementIconContainer>
        <Styled.MeasurementDataContainer>
          <span>{Math.round(pm1)} {this.renderPMSymbol()}</span>
        </Styled.MeasurementDataContainer>
      </Styled.MeasurementItem>
    )
  }

  renderForecastPlot() {
    const { forecastPlotDomain, forecastPlotData, forecastTooltipValue } = this.state;
    const FlexibleXYPlot = makeVisFlexible(XYPlot);

    return (
      <Styled.PlotContainer>
        <Styled.PlotHeader>Prognoza zanieczyszczenia</Styled.PlotHeader>
        <FlexibleXYPlot
          height={200}
          xType="time"
          yDomain={forecastPlotDomain}
          margin={{ left: 45, right: 25, top: 10, bottom: 20 }}
        >
          <YAxis
            left={-10}
            tickSizeInner={3}
            tickSizeOuter={3}
            style={{
              line: { stroke: '#f4f4f4', strokeWidth: 1 },
              ticks: { stroke: '#f4f4f4', strokeWidth: 1 },
              text: { stroke: 'none', fill: '#909cac', fontWeight: 600, fontSize: 10 }
            }}
          />
          <VerticalBarSeries
            className="vertical-bar-series"
            colorRange={colorRange}
            colorDomain={colorRange.map((color, index) => index)}
            data={forecastPlotData}
            onValueMouseOver={(e) => this._onNearestX('forecastTooltipValue', e)}
            onValueMouseOut={() => this._onMouseLeave('forecastTooltipValue')}
          />
          {forecastTooltipValue && this.renderHint(forecastTooltipValue)}
        </FlexibleXYPlot>
      </Styled.PlotContainer>
    )
  }

  renderHistoryPlot() {
    const { historyPlotDomain, historyPlotData, historyTooltipValue } = this.state;
    const FlexibleXYPlot = makeVisFlexible(XYPlot);

    return (
      <Styled.PlotContainer>
        <Styled.PlotHeader>Dane historyczne</Styled.PlotHeader>
        <FlexibleXYPlot
          height={200}
          xType="time"
          yDomain={historyPlotDomain}
          margin={{ left: 45, right: 25, top: 10, bottom: 20 }}
        >
          <YAxis
            left={-10}
            tickSizeInner={3}
            tickSizeOuter={3}
            style={{
              line: { stroke: '#f4f4f4', strokeWidth: 1 },
              ticks: { stroke: '#f4f4f4', strokeWidth: 1 },
              text: { stroke: 'none', fill: '#909cac', fontWeight: 600, fontSize: 10 }
            }}
          />
          <VerticalBarSeries
            className="vertical-bar-series"
            colorRange={colorRange}
            colorDomain={colorRange.map((color, index) => index)}
            data={historyPlotData}
            onValueMouseOver={(e) => this._onNearestX('historyTooltipValue', e)}
            onValueMouseOut={() => this._onMouseLeave('historyTooltipValue')}
          />
          {historyTooltipValue && this.renderHint(historyTooltipValue)}
        </FlexibleXYPlot>
      </Styled.PlotContainer>
    )
  }

  renderHint(value) {
    return (
      <Hint value={value}>
        <Styled.CrosshairDataContainer>
          <Styled.CrosshairHourRow>{value.datetime.hour}</Styled.CrosshairHourRow>
          <Styled.CrosshairDataRow>CAQI: {value.y}</Styled.CrosshairDataRow>
        </Styled.CrosshairDataContainer>
      </Hint>
    )
  }

  renderMeasurements() {
    const { description, advice, temperature, humidity, pressure, pm10, pm25, pm1, forecast, history } = this.props;

    return (
      <>
        {(description && advice) ? this.renderCAQIMessage() : null}
        {temperature ? this.renderTemperature() : null}
        {humidity ? this.renderHumidity() : null}
        {pressure ? this.renderAirPressure() : null}
        {pm10 ? this.renderPM10() : null}
        {pm25 ? this.renderPM25() : null}
        {pm1 ? this.renderPM1() : null}
        {forecast && forecast.length > 0 ? this.renderForecastPlot() : null}
        {history && history.length > 0 ? this.renderHistoryPlot() : null}
      </>
    )
  }

  renderError() {
    return (
      <Styled.ErrorContainer>Brak danych z czujnika</Styled.ErrorContainer>
    )
  }

  render() {
    const {level} = this.props;

    return (
      <>
        <Styled.MeasurementsContainer>
          {(level !== 'UNKNOWN' || Object.keys(this.props).length > 0) ? this.renderMeasurements() : this.renderError()}
        </Styled.MeasurementsContainer>
      </>
    )
  }
}

export default Sensor;