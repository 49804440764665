const LAYER_SYMBOLS = {
  PARKINGS_POINT: 'PARKINGS_POINT',
  PARKINGS_POLYGON: 'PARKINGS_POLYGON',
  PARKING_METERS: 'PARKING_METERS',
  PARKING_ZONES: 'PARKING_ZONES',
  VEHICLES: 'VEHICLES',
  STOPS: 'STOPS',
  VMS: 'VMS',
  ANPR: 'ANPR',
  CCTV: 'CCTV',
  AIRLY_CLOUD: 'AIRLY_CLOUD',
  AIRLY_MEASUREMENT: 'AIRLY_MEASUREMENT',
  MASS_EVENT: 'MASS_EVENT',
  CONSTRUCTION: 'CONSTRUCTION',
  WARNING: 'WARNING',
  BREAKDOWN: 'BREAKDOWN'
};

export default LAYER_SYMBOLS;