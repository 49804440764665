export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": 1,
        "name": "1",
        "direction": "Dworzec PKP"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332112789154053,
          50.47594282206051
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 2,
        "name": "2",
        "direction": "Dworzec PKP"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.339365482330322,
          50.477636099612056
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 3,
        "name": "3",
        "direction": "Dworzec PKP"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33790636062622,
          50.471586450266116
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 4,
        "name": "4",
        "direction": "Dworzec PKP"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33805656433105,
          50.46884132018261
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 5,
        "name": "5",
        "direction": "Dworzec PKP"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3329496383667,
          50.46634188403126
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 6,
        "name": "6",
        "direction": "Dworzec PKP"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.328271865844723,
          50.47161376419249
        ]
      }
    }
  ]
}