import styled from 'styled-components';

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Tabs = styled.div`
  width: calc(100% - 2rem);
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid transparent;
  overflow: hidden;
  cursor: pointer;
`;

export const Tab = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  
  background: ${props => props.isActive ? '#4b535e' : '#f2f3f5'};
  color: ${props => props.isActive ? '#ffffff' : '#909cac'};
`;
