const GROUP_SYMBOLS = {
  PARKINGS: 'PARKINGS',
  PARKING_METERS: 'PARKING_METERS',
  VEHICLES: 'VEHICLES',
  STOPS: 'STOPS',
  VMS: 'VMS',
  CAMERAS: 'CAMERAS',
  SENSORS: 'SENSORS',
  EVENTS: 'EVENTS'
};

export default GROUP_SYMBOLS;