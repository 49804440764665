import styled from 'styled-components';

export const Button = styled.button`
    padding: 0.6rem;
    border: 2px solid transparent;
    border-radius: 4px;
    background: ${props => props.color ? props.color : '#909CAC'};
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    
    :hover {
      background: #768191;
    }
    
    :active {
      background: #68717E;
      border: 2px solid #909CAC;
    }
  `;
