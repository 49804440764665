import React, {Component} from 'react';
import * as Styled from './VariableMessageSigns.styles';
import VMSService from '../../../../../services/vms';

const IMAGE_SIZE = {
  width: 300,
  height: 230
};

class VariableMessageSigns extends Component {
  interval = undefined;
  state = {
    image: undefined
  };

  componentDidMount() {
    this.downloadImage();
    this.interval = setInterval(() => this.downloadImage(), 3000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.controller.abort();
      clearInterval(this.interval);

      this.setState({image: undefined});

      this.downloadImage();
      this.interval = setInterval(() => this.downloadImage(), 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.controller.abort();
  }

  downloadImage = () => {
    const id = this.props.id;

    this.controller = new AbortController();
    const signal = this.controller.signal;

    VMSService.getImage({id, height: IMAGE_SIZE.height, width: IMAGE_SIZE.width, signal})
      .then(image => this.setState({ image }))
  };

  renderLoading() {
    return (
      <Styled.LoadingContainer>Wczytywanie...</Styled.LoadingContainer>
    );
  }

  render() {
    return (
      <>
        {
          this.state.image ? <Styled.Image src={`data:image/jpg;base64,${this.state.image}`}/> : this.renderLoading()
        }
      </>
    )
  }
}

export default VariableMessageSigns;
