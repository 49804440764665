export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "Parkometr 1"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.337101697921753,
          50.47760196155086
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Parkometr 2"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335771322250366,
          50.476919195149414
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Parkometr 3"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.338979244232178,
          50.47601110055473
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Parkometr 4"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.338474988937378,
          50.47451577896148
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Parkometr 5"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.333786487579346,
          50.47305455143804
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Parkometr 6"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.330632209777832,
          50.472713136629054
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335084676742554,
          50.478107202338755
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33144760131836,
          50.47573798598615
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.32943058013916,
          50.47282921794075
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332981824874878,
          50.47111528255243
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335234880447388,
          50.469790524979935
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.338571548461914,
          50.47063045290194
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33921527862549,
          50.47179130432924
        ]
      }
    }

  ]
}