import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Departure = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #f4f4f4;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CancelledSign = styled.span`
  transform: rotate(10deg);
  font-size: 20px;
  color: #E16D6D;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
`

export const DepartureMetadata = styled.div`
  width: 100%;
  height: 100%;
  filter: ${props => props.isCancelled ? 'blur(1px) grayscale(100%) opacity(50%)' : ''};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EstimatedTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  font-weight: bolder;
  color: ${props => props.estimatedTime <= 5 ? '#E16D6D' : '#4B535E'};
`;

export const EstimatedTimeDigits = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  font-size: 32px;
  letter-spacing: -2px;
`;

export const EstimatedTimeSymbol = styled.div`
  font-size: 12px;
  align-self: flex-end;
  justify-self: flex-end;
`;

export const DirectionMetadata = styled.div`
  width: 110px;
  color: #909CAC;
  display: flex;
  flex-direction: column;
`;

export const DirectionName = styled.span`
  font-size: 11px;
  margin-bottom: 3px;
`;

export const DepartureTime = styled.span`
  font-size: 11px;
  font-weight: bold;
`;

export const GPSIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-left: 6px;
  top: 3px;
  position: relative;
`;

export const LineNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  font-size: 17px;
  font-weight: bold;
  color: #4B535E;
  border: 1px solid #4B535E24;
`;
