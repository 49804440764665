export const checkVisibility = (layer, zoom) => {
  const breakpoint = layer.get('visibilityBreakpoint');
  const hasBreakpoint = breakpoint.hasBreakpoint;

  if (hasBreakpoint) {
    const threshold = breakpoint.threshold;
    const condition = threshold.condition;

    const result = condition === 'equal' ? zoom === threshold.level :
                   condition === 'below' ? zoom < threshold.level   :
                   condition === 'above' ? zoom > threshold.level   : false;

    return result;
  }
  else return true;
};