import {isJSON} from "../helpers/isJSON";
import {toast} from "react-toastify";

export function responseMiddleware(response) {
  if (!response.ok) {
    if ([401, 403, 404].indexOf(response.status) !== -1) {
      console.error(`Response returned with ${response.status} status`);
      throw new Error(`Response returned with ${response.status} status`);
    }
    if (response.status === 500) {
      toast.error("Wystąpił niespodziewany błąd", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

      console.error(`Unexpected error occured`);
      throw new Error('Unexpected error occured');
    }
  }
  else {
    return response.text().then(text => {
      return isJSON(text) ? JSON.parse(text) : text;
    })
  }
}