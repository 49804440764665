import React from 'react';
import * as Styled from './Information.styles'

const renderDirection = (direction) => (
  <Styled.DataContainer>
    <Styled.Header>
      Kierunek
    </Styled.Header>
    <Styled.Content>
      {direction ? direction : "Brak danych"}
    </Styled.Content>
  </Styled.DataContainer>
);

const renderNextStop = (nextStop) => (
  <Styled.DataContainer>
    <Styled.Header>
      Następny przystanek
    </Styled.Header>
    <Styled.Content>
      {
        nextStop?
          (
            <>
              <Styled.NextStopMetadata>
                <span>{nextStop}</span>
              </Styled.NextStopMetadata>
            </>
          )  :
        'Brak danych'
      }
    </Styled.Content>
  </Styled.DataContainer>
);

const renderSideNumber = (sideNumber) => (
  <Styled.DataContainer>
    <Styled.Header>
      Number boczny
    </Styled.Header>
    <Styled.Content>
      {sideNumber ? sideNumber : 'Brak danych'}
    </Styled.Content>
  </Styled.DataContainer>
);

const Information = (props) => {
  const {nextStop, sideNumber, direction} = props.data;

  return (
    <Styled.Container>
      {renderDirection(direction)}
      {renderNextStop(nextStop)}
      {renderSideNumber(sideNumber)}
    </Styled.Container>
  )
};

export default Information;
