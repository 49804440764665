import {format} from "date-fns";

export const colorRange = ['#6BC926', '#D1CF1E', '#EFBB0F', '#EF7120', '#EF2A36', '#B00057', '#770078'];

export const calculateYDomain = (data) => {
  const domain = data.reduce(
    (res, row) => {
      return {
        max: Math.max(res.max, row.y),
        min: Math.min(res.min, row.y)
      };
    },
    {max: -Infinity, min: Infinity}
  );

  return [0, Math.ceil(domain.max)];
};

export const transformData = (data, key) => {
  return data.map((row, index) => {
    return {
      x: index,
      y: row[key],
      datetime: transformDateTime(row.fromDateTime),
      color: attachColorIndex(row.level)
    }
  });
};

const attachColorIndex = (value) => {
    switch (value) {
    case 'VERY_LOW'   : return 0;
    case 'LOW'        : return 1;
    case 'MEDIUM'     : return 2;
    case 'HIGH'       : return 3;
    case 'VERY_HIGH'  : return 4;
    case 'EXTREME'    : return 5;
    case 'AIRMAGEDDON': return 6;
    default: return 0;
  }
};

const transformDateTime = date => {
  return {
    date: format(date, 'DD-MM-YYYY'),
    hour: format(date, 'HH:mm')
  };
};