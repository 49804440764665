import OlView from 'ol/View';

export const createView = ({boundingBox, center, zoom}) => {
  return new OlView({
    minZoom: 14,
    extent: boundingBox,
    center,
    zoom,
  });
};

export const calculateTopPadding = (layerSymbol) => {
  switch (layerSymbol) {
    case 'PARKINGS_POINT':
      return 100;
    default:
      return 50;
  }
};

export const mobileZoom = (e, map) => {
  const extent = e.selected[0].getGeometry().getExtent();
  const layerSymbol = e.selected[0].getProperties().layer;

  map.getView().fit(extent, {
    size: map.getSize(),
    padding: [calculateTopPadding(layerSymbol), 10, map.getSize()[1] / 2, 10],
    duration: 500,
    maxZoom: map.getView().getZoom()
  });
};

export const desktopZoom = (e, map) => {
  const extent = e.selected[0].getGeometry().getExtent();

  map.getView().fit(extent, {
    size: map.getSize(),
    duration: 500,
    maxZoom: map.getView().getZoom()
  });
};

export const centerViewOnFeature = (e, map) => {
  const isMobile = window.innerWidth <= 480;

  isMobile ? mobileZoom(e, map) : desktopZoom(e, map);
};
