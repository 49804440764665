import React from 'react';
import ReactDOM from 'react-dom';

import Overlay from 'ol/Overlay';
import Select from 'ol/interaction/Select';
import {click, pointerMove, never} from 'ol/events/condition';

import DefaultTooltip from '../tooltips/DefaultTooltip/DefaultTooltip';
import ParkingSpotTooltip from '../tooltips/ParkingSpotTooltip/ParkingSpotTooltip';

import {centerViewOnFeature} from './view';
import {HoverStylingFunction, SelectStylingFunction} from "./layer-style";
import LAYER_SYMBOLS from "../../../constants/layer-symbols";
import SensorsService from '../../../services/sensors';

const DEFAULT_TOOLTIP_OFFSET = [0, -110];
export const INTERACTION_TYPES = {
  HOVER: 'HOVER',
  SELECT: 'SELECT'
};

export const createTooltip = (container) => {
  return new Overlay({
    element: container,
    positioning: 'top-center',
    offset: DEFAULT_TOOLTIP_OFFSET,
    stopEvent: true
  });
};

export const initializeInteractions = ({map, layers, tooltip, selectCallback}) => {
  const interactions = [
    _initializeHover({layers, tooltip}),
    _initializeSelect({map, layers, tooltip, callback: selectCallback})
  ];

  return interactions;
};

const _initializeHover = ({layers, tooltip}) => {
  const hover = new Select({
    condition: pointerMove,
    layers: layers.filter(layer => layer.get('isInteractive')),
    style: (feature, resolution) => HoverStylingFunction(feature, resolution)
  });

  hover.setProperties({type: INTERACTION_TYPES.HOVER});

  hover.on('select', (e) => {
    if (!tooltip.persist) {
      if (e.selected.length > 0) {
        const selected = e.selected[0];
        const content = {
          name: selected.get('name')
        };

        if (content.name) {
          _setHoverTooltipContent(content);
          _setTooltipPosition(e, tooltip);
        } else tooltip.setPosition(undefined);
      } else tooltip.setPosition(undefined);
    }
  });

  return hover;
};

const _initializeSelect = ({map, layers, tooltip, callback}) => {
  const select = new Select({
    condition: click,
    toggleCondition: never,
    multi: false,
    layers: layers.filter(layer => layer.get('isInteractive')),
    style: (feature, resolution) => SelectStylingFunction(feature, resolution)
  });

  select.setProperties({type: INTERACTION_TYPES.SELECT});

  select.on('select', (e) => {
    if (e.selected.length > 0) {
      const selected = e.selected[0];
      const layer = selected.get('layer');

      getSelectedDetails(selected, layer, layers).then(result => {
        const {properties, content} = result;

        callback(properties);
        _setSelectTooltipContent(content, layer);
        _setTooltipPosition(e, tooltip);

        centerViewOnFeature(e, map);
        tooltip.persist = true;
      });
    } else {
      callback(undefined);
      tooltip.setPosition(undefined);
      tooltip.persist = false;
    }
  });

  return select;
};

const _setHoverTooltipContent = (content) => {
  const container = document.getElementById('popup-content');

  ReactDOM.render(<DefaultTooltip content={content}/>, container);
};

const _setSelectTooltipContent = (content, symbol) => {
  const container = document.getElementById('popup-content');

  switch (symbol) {
    case LAYER_SYMBOLS.PARKINGS_POINT: {
      ReactDOM.render(<ParkingSpotTooltip content={content}/>, container);
      break;
    }
    default: {
      ReactDOM.render(<DefaultTooltip content={content}/>, container);
      break;
    }
  }
};

const _setTooltipPosition = (e, tooltip) => {
  const feature = e.selected[0];
  const symbol = feature.get('layer');
  const geometryType = feature.getGeometry().getType();

  switch (geometryType) {
    case 'Point': {
      const coordinates = feature.getGeometry().getCoordinates();
      tooltip.setPosition(coordinates);
      break;
    }
    case 'GeometryCollection': {
      const geometries = feature.getGeometry().getGeometries();
      const point = geometries.find(geometry => geometry.getType() === 'Point');

      if (point) {
        const coordinates = point.getCoordinates();
        tooltip.setPosition(coordinates);
      } else tooltip.setPosition(undefined);

      break;
    }
    default: {
      tooltip.setPosition(undefined);
      break;
    }
  }

  _setTooltipOffset(tooltip, symbol);
};

const _setTooltipOffset = (tooltip, symbol) => {
  switch (symbol) {
    case LAYER_SYMBOLS.PARKINGS_POINT:
      tooltip.setOffset([0, -190]);
      break;
    default:
      tooltip.setOffset(DEFAULT_TOOLTIP_OFFSET)
  }
};

const getSelectedDetails = (feature, layer, layers) => {
  return new Promise((resolve, reject) => {
    switch (layer) {
      case LAYER_SYMBOLS.PARKINGS_POINT: {
        const parkingId = feature.get('parkingId');
        const refLayer = layers.find(layer => layer.get('symbol') === LAYER_SYMBOLS.PARKINGS_POLYGON);
        const refFeature = refLayer.getSource().getFeatureById(parkingId);

        if (refFeature) {
          const content = {
            name: refFeature.get('name'),
            displayName: feature.get('displayName'),
            coordinates: feature.getGeometry().getCoordinates()
          };

          const properties = refFeature.getProperties();

          resolve({content, properties});
        }

        reject();
      }
        break;
      case LAYER_SYMBOLS.STOPS: {
        const id = feature.getId();
        const code = feature.get('code');

        if (id && code) {
          const data = {
            code,
            id,
          };

          const content = {
            name: feature.get('name'),
          };

          const properties = {
            data,
            layer: LAYER_SYMBOLS.STOPS,
            name: feature.get('name')
          };

          resolve({content, properties});
        }
      }
        break;
      case LAYER_SYMBOLS.VEHICLES: {
        const id = feature.getId();
        const {direction, nextStop, nextStopTime, routeNumber, sideNumber} = feature.getProperties()

        if (id) {
          const data = {
            information: {
              direction,
              nextStop,
              nextStopTime,
              routeNumber,
              sideNumber
            },
          };

          const content = {
            name: routeNumber,
          };

          const properties = {
            data,
            layer: LAYER_SYMBOLS.VEHICLES,
            name: routeNumber,
            subname: direction
          };

          resolve({content, properties});
        }
      }
        break;
      case LAYER_SYMBOLS.AIRLY_MEASUREMENT: {
        const id = feature.getId();

        SensorsService.getSensorDetails(id)
          .then(result => {
            const content = {
              name: feature.get('name'),
            };

            const properties = {
              ...result,
              layer: LAYER_SYMBOLS.AIRLY_MEASUREMENT,
              name: feature.get('name')
            };

            resolve({content, properties});
          })
          .catch(() => reject())
      }
        break;
      default: {
        const content = {
          name: feature.get('name')
        };
        const properties = feature.getProperties();

        resolve({content, properties});
      }
    }
  });
};
