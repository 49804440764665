import { responseMiddleware } from './response-middleware';
import PATHS from '../constants/paths';
import EmptyDataset from '../constants/mock-data/features/empty-dataset';

export default {
  getEventsByType
}

function getEventsByType(type) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/event?type=${type}`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}
