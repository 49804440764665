import { responseMiddleware } from './response-middleware';
import PATHS from '../constants/paths';
import EmptyDataset from "../constants/mock-data/features/empty-dataset";

export default {
  getSensors,
  getSensorDetails
}

function getSensors() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/air-sensor/installations`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}

function getSensorDetails(id) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/air-sensor/measurement/${id}`, requestOptions)
    .then(responseMiddleware)
}