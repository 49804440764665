import React, {useContext, useEffect, useState} from 'react';
import * as Styled from "./Timetable.styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Tooltip} from 'react-tippy';

import {LegendContext} from "../Timetables";
import Legend from "./Legend";
import StopsService from '../../../../../../../services/stops';

const createDataset = (departures) => {
  const result = [];

  departures.forEach(departure => {
    const split = departure.time.split(':');
    const hour = split[0];
    const minutes = split[1];

    const matchingRecord = result.find(dataset => dataset.hour === hour);

    if (matchingRecord) matchingRecord.minutes.push({
      time: minutes,
      footnotes: departure.footnotes
    });
    else result.push({hour, minutes: [{time: minutes, footnotes: departure.footnotes}]});
  });

  return result;
};

const TimetableRow = ({data}) => (
  <Styled.RowContainer>
    <Styled.Hour>{data.hour}</Styled.Hour>
    {
      data.minutes.map(minute => RenderMinuteWithFootnote(data.hour, minute))
    }
  </Styled.RowContainer>
);

const RenderMinuteWithFootnote = (hour, minute) => {
  return (
    <span key={`${hour}-${minute.time}`}>
      <Styled.Minute>
        {minute.time}
      </Styled.Minute>
      <Styled.Footnote>
        {minute.footnotes.map(({footnote, footnoteDescription}) => (
          <Tooltip title={footnoteDescription} key={footnote}>
            <span>{footnote}</span>
          </Tooltip>
        ))}
      </Styled.Footnote>
    </span>
  )
}

const Timetable = (props) => {
  const {timetable} = props;

  const {stopId, lineNumber} = useContext(LegendContext);
  const [legend, setLegend] = useState([]);

  const dataset = createDataset(timetable.departures);

  useEffect(() => {
    setLegend([])

    if (timetable && stopId && lineNumber) {
      fetchLegend(stopId, lineNumber, timetable.date);
    }
  }, [timetable, stopId, lineNumber])

  const fetchLegend = (stopId, lineNumber, date) => {
    StopsService.getFootnotesLegend(stopId, lineNumber, date)
      .then(legend => setLegend(legend));
  }

  return (
    <Styled.Container>
      <PerfectScrollbar>
        {dataset.map(record => <TimetableRow key={record.hour} data={record}/>)}
        <Legend data={legend} />
      </PerfectScrollbar>
    </Styled.Container>
  );
};

export default Timetable;
