import styled from 'styled-components';

export const LineNumbersContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
`;

export const NoLinesNotification = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
`;

export const LineNumber = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  
  color: ${props => props.isActive ? '#4b535e' : '#909cac'};
  border: 1px solid ${props => props.isActive ? '#909cac' : '#f2f3f5'};
  box-shadow: ${props => props.isActive ? '0px 1px 3px #00000029' : 'none'};
`;