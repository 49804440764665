import {getCenter} from 'ol/extent';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';
import GeometryCollection from 'ol/geom/GeometryCollection';
import LAYER_SYMBOLS from '../../../constants/layer-symbols';

import ParkingsService from '../../../services/parkings';
import EventsService from '../../../services/events';
import SensorsService from '../../../services/sensors';
import VMSServices from '../../../services/vms';
import CamerasService from '../../../services/cameras';
import ParkingZonesService from '../../../services/parking-zones';
import VehiclesService from '../../../services/vehicles';
import StopsService from '../../../services/stops';

export const loadFeatures = ({ groupSymbol, layer }) => {
  return new Promise((resolve, reject) => {
    const { type, style, symbol } = layer.getProperties();

    _queryFeatures(symbol).then(data => {
      const features = (new GeoJSON()).readFeatures(data, {
        featureProjection: 'EPSG:3857'
      });

      features.forEach(feature => {
        feature.set('geometryType', type);
        feature.set('style', style);
        feature.set('layer', symbol);
        feature.set('symbol', groupSymbol);
        feature.setId(feature.get('id'));
      });

      switch (symbol) {
        case LAYER_SYMBOLS.BREAKDOWN:
        case LAYER_SYMBOLS.CONSTRUCTION:
        case LAYER_SYMBOLS.PARKINGS_POLYGON:
          resolve(_createCenterMarker(features));
          break;
        default:
          resolve(features);
          break;
      }
    })
  })
};

const _queryFeatures = (symbol) => {
  return new Promise((resolve, reject) => {
    switch(symbol) {
      case LAYER_SYMBOLS.PARKINGS_POLYGON:
        ParkingsService.getParkings().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.PARKINGS_POINT:
        ParkingsService.getParkingPlaces().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.ANPR:
        CamerasService.getANPR().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.CCTV:
        CamerasService.getCCTV().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.VMS:
        VMSServices.getVMS().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.PARKING_METERS:
        ParkingZonesService.getParkingMeters().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.PARKING_ZONES:
        ParkingZonesService.getParkingZones().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.VEHICLES:
        VehiclesService.getVehicles().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.RELATED_STOPS:
        resolve({}); //TODO: create alternative service to download related features
        break;
      case LAYER_SYMBOLS.STOPS:
        StopsService.getStops().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.RELATED_VEHICLES:
        resolve({}); //TODO: create alternative service to download related features
        break;
      case LAYER_SYMBOLS.AIRLY_CLOUD:
      case LAYER_SYMBOLS.AIRLY_MEASUREMENT:
        SensorsService.getSensors().then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.MASS_EVENT:
        EventsService.getEventsByType(LAYER_SYMBOLS.MASS_EVENT).then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.CONSTRUCTION:
        EventsService.getEventsByType(LAYER_SYMBOLS.CONSTRUCTION).then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.WARNING:
        EventsService.getEventsByType(LAYER_SYMBOLS.WARNING).then(data => resolve(data));
        break;
      case LAYER_SYMBOLS.BREAKDOWN:
        EventsService.getEventsByType(LAYER_SYMBOLS.BREAKDOWN).then(data => resolve(data));
        break;
      default:
        console.error('Wrong symbol');
        reject();
        break;
    }
  })
};

const _createCenterMarker = (features) => {
  return features.map(feature => {
    const extent = feature.getGeometry().getExtent();
    const center = getCenter(extent);

    const geometry = new GeometryCollection([
      feature.getGeometry(),
      new Point(center)
    ]);

    feature.set('center', center);
    feature.setGeometry(geometry);
    return feature;
  });
};

export const resetFeatures = (layer, GeoJSONFeatures) => {
  const features = (new GeoJSON()).readFeatures(GeoJSONFeatures, {
    featureProjection: 'EPSG:3857'
  });

  if (features) {
    layer.getSource().clear();
    layer.getSource().addFeatures(features);
  }
}

export const updateFeatureProperty = (feature, property, value) => {
  feature.setProperties({[property]: value});
};

export const updateLayerFeaturesCoordinates = (layer, features) => {
  const layerFeatures = layer.getSource().getFeatures();

  layerFeatures.forEach(layerFeature => {
    const sideNumber = layerFeature.get('sideNumber');
    const GeoJSONFeature = features.find(feature => feature.properties.sideNumber === sideNumber);

    if (GeoJSONFeature && GeoJSONFeature.geometry) {
      const feature = (new GeoJSON()).readFeature(GeoJSONFeature, {
        featureProjection: 'EPSG:3857'
      });

      updateFeatureCoordinates(layerFeature, feature.getGeometry().getCoordinates())
    }
  })
}

export const updateFeatureCoordinates = (feature, coordinates) => {
  feature.getGeometry().setCoordinates(coordinates);
}
