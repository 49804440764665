import {Fill, Stroke, Style} from 'ol/style.js';

export default {
  default: new Style({
    stroke: new Stroke({
      color: '#84a0b7',
      width: 1,
      lineDash: [3, 3]
    }),
    fill: new Fill({
      color: '#84a0b732'
    })
  }),
  hovered: new Style({
    stroke: new Stroke({
      color: '#84a0b7',
      width: 2
    }),
    fill: new Fill({
      color: '#84a0b732',
    })
  }),
  selected: new Style({
    stroke: new Stroke({
      color: '#6F78AF',
      width: 3
    }),
    fill: new Fill({
      color: '#6F78AF64'
    })
  })
}