import React, {createContext, useState, useEffect} from 'react';
import * as Styled from './Timetables.styles';
import LineNumbers from './LineNumbers';
import LineDetails from './LineDetails';

export const LegendContext = createContext([]);

const Timetables = ({data, stopId}) => {
  const [selectedLine, setSelectedLine] = useState(undefined);

  useEffect(() => {
    setSelectedLine(undefined)
  }, [data.lines]);

  return (
    <Styled.Container>
      <LineNumbers
        setSelectedLine={setSelectedLine}
        selectedLine={selectedLine}
        lines={data.lines}
      />
      {
        selectedLine ? (
          selectedLine.timetables && selectedLine.timetables.length > 0 ?
            (
              <LegendContext.Provider
                value={{
                  stopId,
                  lineNumber: selectedLine.number
                }}
              >
                <LineDetails line={selectedLine}/>
              </LegendContext.Provider>
            )
            :
            <Styled.NoTimetables>Brak rozkładu</Styled.NoTimetables>
        ) : null
      }
    </Styled.Container>
  )
};

export default Timetables;
