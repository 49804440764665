import React from 'react';
import LayersContext from '../../../LayersContext';

import LAYERS from '../../../constants/layers';
import MenuItem from "./MenuItem";

const Consumer = LayersContext.Consumer;

const Menu = ({layersVisibility}) => {
  return (
    <Consumer>
      {context =>
        <>
          {LAYERS.map(layer =>
            <MenuItem
              key={layer.symbol}
              iconPath={layer.menuIcon}
              activeColor={layer.activeColor}
              name={layer.name}
              symbol={layer.symbol}
              isVisible={layersVisibility[layer.symbol]}
              isActive={context.layers[layer.symbol]}
              handleClick={() => context.onLayerSelected(layer.symbol)}
            />
          )}
        </>
      }
    </Consumer>
  )
};

export default Menu;
