import React from 'react';
import * as Styled from './Departures.styles'
import {Tooltip} from 'react-tippy';
import GPSIcon from '../../../../../../assets/gfx/icons/gps.svg';
import GPSOffIcon from '../../../../../../assets/gfx/icons/gps-off.svg';

const renderEstimatedTime = (time) => {
  return time === 0  ? '< 1'                       :
         time === -1 ? '>>>'                       :
         time >= 60  ? `~${Math.round(time / 60)}` : time.toString().padStart(2, '0');
};

const renderEstimatedTimeSymbol = (timeLeft) => {
  return timeLeft === -1 ? ''    :
         timeLeft < 60   ? 'min' : 'godz.';
};

const renderGPSStatus = (estimated) => {
  return estimated ? (
    <Tooltip title="Czas GPS">
      <Styled.GPSIcon src={GPSIcon} />
    </Tooltip>
  ) : (
    <Tooltip title="Czas z rozkładu">
      <Styled.GPSIcon src={GPSOffIcon} />
    </Tooltip>
  )
};

const Departure = (props) => {
  const {timeLeft, direction, departureTime, line, estimated, cancelled} = props.data;

  return (
    <Styled.Departure>
      {cancelled && <Styled.CancelledSign>Odwołany</Styled.CancelledSign>}
      <Styled.DepartureMetadata isCancelled={cancelled}>
        <Styled.EstimatedTime estimatedTime={timeLeft}>
          <Styled.EstimatedTimeDigits>{renderEstimatedTime(timeLeft)}</Styled.EstimatedTimeDigits>
          <Styled.EstimatedTimeSymbol>{renderEstimatedTimeSymbol(timeLeft)}</Styled.EstimatedTimeSymbol>
        </Styled.EstimatedTime>
        <Styled.DirectionMetadata>
          <Styled.DirectionName>{direction}</Styled.DirectionName>
          <Styled.DepartureTime>
            <span>{departureTime}</span>
            {renderGPSStatus(estimated)}
          </Styled.DepartureTime>
        </Styled.DirectionMetadata>
        <Styled.LineNumber>{line}</Styled.LineNumber>
      </Styled.DepartureMetadata>
    </Styled.Departure>
  )
};

const Departures = (props) => {
  return (
    <Styled.Container>
      {props.data.map((departure, index) => <Departure key={index} data={departure}/>)}
    </Styled.Container>
  )
};

export default Departures;
