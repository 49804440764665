import styled from "styled-components";

export const Container = styled.div`
  user-select: none;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

export const RowContainer = styled.div`
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: repeat(7, 40px);
`;

export const Hour = styled.span`
  cursor: default;
  color: #4b535e;
  font-weight: bold;
  font-size: 14px;
  margin-right: 1rem;
`;

export const Minute = styled.span`
  cursor: default;
  color: #909cac;
  font-size: 14px;
  margin-right: 0.5rem;
`;

export const Footnote = styled.span`
  cursor: default;
  color: #909cac;
  font-size: 10px;
  position: relative;
  top: 7px;
  right: 8px;
`
