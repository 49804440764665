import React, {Component} from 'react';
import * as Styled from './Header.styles';
import LayersContext from '../../LayersContext';
import LayersService from '../../services/layers';

import Menu from './menu/Menu';
import MenuMobile from './menu-mobile/MenuMobile';

class Header extends Component {
  static contextType = LayersContext;
  state = {
    isMenuMobileOpen: false,
    layersVisibility: {}
  };

  componentDidMount() {
    LayersService.getLayersVisibility()
      .then(response => {
        const layersVisibility = LayersService.createVisibilityMap(response)

        this.setState({layersVisibility});
      })
  }

  toggleMenu() {
    this.setState({ isMenuMobileOpen: !this.state.isMenuMobileOpen })
  }

  renderMenu() {
    const {isMenuMobileOpen, layersVisibility} = this.state;

    return this.context.isMobile ?
      <MenuMobile
        layersVisibility={layersVisibility}
        isMenuMobileOpen={isMenuMobileOpen}
        onToggleMenu={this.toggleMenu.bind(this)} /> :
      <Menu layersVisibility={layersVisibility} />
  }

  render() {
    return (
      <Styled.Header mobileMenuActive={this.state.isMenuMobileOpen}>
        {this.renderMenu()}
      </Styled.Header>
    )
  }
}

export default Header;
