import styled from 'styled-components';

export const HeaderContainer = styled.div`
    margin: 0.75rem 0;
    padding: 0.75rem;
    text-align: center;
`;

export const HeaderItemType = styled.p`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #909cac;
    margin: 0;
`;

export const HeaderItemName = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: #4b535e;
    margin: 0.5rem 0 0;
`;

export const Icon = styled.span`
    font-size: 16px;
    font-weight: bold;
`;

export const HeaderItemSubname = styled.p`
    font-size: 12px;
    color: #909cac;
    margin: 0;
    margin-top: 0.25rem;
`;
