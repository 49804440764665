import {Icon, Style} from 'ol/style.js';
import SensorsMarker from '../../assets/gfx/markers/sensor.svg'
import SensorsSelectedMarker from '../../assets/gfx/markers/sensor-selected.svg'

export default {
  default: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: SensorsMarker
    })
  }),
  hovered: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: SensorsMarker,
      color: '#f8f8f8'
    })
  }),
  selected: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: SensorsSelectedMarker
    })
  }),
}