import React from 'react';
import * as Styled from './MenuMobileItem.styles';

const MenuMobileItem = (props) => {
  return (
    props.isVisible ? (
      <Styled.MenuItem
        activeColor={props.activeColor}
        active={props.isActive}
        onClick={props.handleClick}
      >
        <Styled.ImageContainer
          activeColor={props.activeColor}
          active={props.isActive}
        >
          <img src={props.iconPath}  alt={props.name}/>
        </Styled.ImageContainer>
        <Styled.LabelContainer
          active={props.isActive}
        >
          {props.name}
        </Styled.LabelContainer>
      </Styled.MenuItem>
      ) : null
  )
};

export default MenuMobileItem;
