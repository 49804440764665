import styled from 'styled-components';

export const EventContainer = styled.div`
  border-top: 1px solid #efefef;
`;

export const DataContainer = styled.div`
  padding: 0 0.75rem;
  margin-top: 1.5rem;
`;

export const Header = styled.div`
  color: #909cac;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 0.5rem;
`;

export const Content = styled.div`
  color: #4b535e;
  font-size: 12px;
`;
