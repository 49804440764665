export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "VMS 1"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.336361408233643,
          50.480175902210995
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "VMS 2"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.343335151672363,
          50.4713952523397
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "VMS 3"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.338764667510986,
          50.46795355744395
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "VMS 4"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3268985748291,
          50.476174968538466
        ]
      }
    }
  ]
}