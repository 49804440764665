import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
  `;

export const ContainerHeader = styled.div`
    color: #909cac;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0.75rem 1.25rem;
    border-top: 1px solid #efefef;
  `;

export const ContainerTable = styled.div`
    font-size: 12px;
    padding: 0.5rem 1.25rem;
  `;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
  `;

export const RowName = styled.span`
    color: #909cac;
  `;

export const RowValue = styled.span`
    color: #4b535e;
    font-weight: bold;
    min-width: 40px;
    text-align: right;
  `;