import { responseMiddleware } from './response-middleware';
import PATHS from '../constants/paths';
import EmptyDataset from "../constants/mock-data/features/empty-dataset";

export default {
  getVehicles,
  getVehicleTimetable
}

function getVehicles() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/transport/vehicles`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}

function getVehicleTimetable(vehicleId) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/transport/timetable/${vehicleId}/timetable`, requestOptions)
    .then(responseMiddleware)
}
