import {Icon, Style} from 'ol/style.js';
import ParkingMeterMarker from '../../assets/gfx/markers/parking-meter.svg'
import ParkingMeterMarkerSelectedMarker from '../../assets/gfx/markers/parking-meter-selected.svg'

export default {
  default: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: ParkingMeterMarker
    })
  }),
  hovered: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: ParkingMeterMarker,
      color: '#f8f8f8'
    })
  }),
  selected: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: ParkingMeterMarkerSelectedMarker
    })
  }),
}