import React, {Component} from 'react';
import * as Styled from './Event.styles';
import {format} from 'date-fns';

class Event extends Component {
  renderDescription() {
    return (
      <Styled.DataContainer>
        <Styled.Header>
          Dodatkowe informacje
        </Styled.Header>
        <Styled.Content>
          {this.props.description}
        </Styled.Content>
      </Styled.DataContainer>
    )
  }

  renderValidityPeriod() {
    const {startDate, endDate} = this.props;
    const start = startDate ? format(startDate, 'YYYY-MM-DD') : 'nie wyznaczono';
    const end = endDate ? format(endDate, 'YYYY-MM-DD') : 'nie wyznaczono';

    return (
      <Styled.DataContainer>
        <Styled.Header>
          Okres obowiązywania
        </Styled.Header>
        <Styled.Content>
          {start} - {end}
        </Styled.Content>
      </Styled.DataContainer>
    )
  }

  render() {
    const {description, startDate, endDate} = this.props;

    return (
      <Styled.EventContainer>
        {description          ? this.renderDescription()    : null}
        {startDate || endDate ? this.renderValidityPeriod() : null}
      </Styled.EventContainer>
    )
  }
}

export default Event;