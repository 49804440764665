import {Style, Fill, Stroke, Circle} from 'ol/style.js';
import {Icon} from 'ol/style';
import HandicappedIcon from '../../assets/gfx/icons/handicapped.svg';

export default {
  default: new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: '#39b74d64' }),
      stroke: new Stroke({ color: '#39b74d87', width: 1 })
    })
  }),
  occupied: new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: '#e16d6d87' }),
      stroke: new Stroke({ color: '#e16d6d87', width: 1 })
    })
  }),
  handicapped: new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      scale: 0.75,
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: HandicappedIcon
    })
  }),
  hovered: new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: '#57729b32' }),
      stroke: new Stroke({ color: '#445e87', width: 1 })
    })
  }),
  selected: new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: '#4b535e' }),
      stroke: new Stroke({ color: '#445e87', width: 1 })
    })
  }),
}