import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid #efefef;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DataContainer = styled.div`
  padding: 0 0.75rem;
  margin-top: 1.5rem;
`;

export const Header = styled.div`
  color: #909cac;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 0.5rem;
`;

export const Content = styled.div`
  color: #4b535e;
  font-size: 12px;
`;

export const NextStopMetadata = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
