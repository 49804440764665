import styled from "styled-components";

export const LegendContainer = styled.div`
  margin: 1rem 0;
`

export const LegendHeader = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  color: #4b535e;
  margin-top: 2rem;
  text-align: center;
`

export const LegendRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
`;

export const FootnoteSymbol = styled.div`
  padding: 0 1.5rem 0 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #4b535e;
`;

export const FootnoteDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 11px;
  color: #909cac;
`;

