import styled from 'styled-components';

export const Header = styled.div`
    background: #4b535e;
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 4;
    
    @media (min-width: 1025px) {
      height: 60px;
      min-height: 60px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      height: 80px;
      min-height: 80px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 80px;
      min-height: 80px;
    }
    
    @media (max-width: 767px) {
      height: 80px;
      min-height: 80px;
    }
    
    @media only screen and (max-width: 480px) {
      height: 60px;
      min-height: 60px;
    }
  `;

