import { responseMiddleware } from './response-middleware';
import PATHS from '../constants/paths';
import EmptyDataset from "../constants/mock-data/features/empty-dataset";

export default {
  getStops,
  getStopTimetable,
  getStopDepartures,
  getFootnotesLegend
}

function getStops() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/transport/stop`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}

function getStopTimetable(stopId) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/transport/timetable/${stopId}`, requestOptions)
    .then(responseMiddleware)
}

function getStopDepartures(stopCode) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/transport/departure/${stopCode}`, requestOptions)
    .then(responseMiddleware)
}

function getFootnotesLegend(stopId, lineNumber, date) {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/transport/timetable/${stopId}/${lineNumber}/legend/${date}`, requestOptions)
    .then(responseMiddleware)
}
