import styled from 'styled-components';

export const SpotsContainer = styled.div`
    background: #909cac;
  `;

export const SpotsHeader = styled.div`
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0.75rem 1.25rem;
  `;

export const SpotsIndicators = styled.div`
    margin-top: 0.5rem;
    padding: 0 1.25rem;
  `;

export const SpotsIndicatorRow = styled.div`
    font-size: 12px;
    padding: 4px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;

export const SpotsRowLabel = styled.span`
    color: #fff;
  `;

export const SpotsRowValue = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4b535e;
    background: #fff;
    width: 60px;
    padding: 3px 6px;
    font-weight: bold;
    border: 1px solid transparent;
    border-radius: 10px;
  `;

export const SpotsButtonContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem;
  `;

export const SpotsButton = styled.button`
    width: 100%;
    color: #fff;
    cursor: pointer;
    background: #4b535e;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    
    transition: all 300ms;
    
    :hover {
      background: #545c66;
    }
  `;

export const FeesContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

export const FeesHeader = styled.div`
    color: #909cac;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0.75rem 1.25rem;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
  `;

export const FeesTable = styled.div`
    font-size: 12px;
    padding: 0.5rem 1.25rem;
  `;

export const FeesRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
  `;

export const FeeName = styled.span`
    color: #909cac;
  `;

export const FeeValue = styled.span`
    color: #4b535e;
    font-weight: bold;
    min-width: 40px;
    text-align: right;
  `;