import styled from 'styled-components';

const LIMITED_SIZE_SYMBOLS = ['CAMERAS', 'VMS'];

export const Container = styled.div`
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    
    @media (min-width: 1025px) {
      height: calc(100% - 60px);
    }
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      height: calc(100% - 80px);
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
      height: calc(100% - 80px);
    }
    
    @media (max-width: 767px) {
      height: calc(100% - 80px);
    }
    
    @media only screen and (max-width: 480px) {
      height: calc(100% - 60px);
    }
    
    -webkit-tap-highlight-color: transparent;
  `;

export const Map = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
  `;

export const OverlayContainer = styled.div`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 300px;
    margin: 1rem;
    background: #fff;
    border-radius: 0.25rem;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px -16px rgba(0,0,0,0.75);
    z-index: 3;
    
    @media (min-width: 1025px) {
      height: 100%;
      max-height: ${props => {
        const isLimitedSizeGroup = LIMITED_SIZE_SYMBOLS.find(symbol => symbol === props.symbol);
        
        return isLimitedSizeGroup ? '300px' : 'calc(100% - 2rem)';
      }};
    }
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      height: 100%;
      max-height: ${props => {
        const isLimitedSizeGroup = LIMITED_SIZE_SYMBOLS.find(symbol => symbol === props.symbol);
      
        return isLimitedSizeGroup ? '300px' : 'calc(100% - 2rem)';
    }};
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 100%;
      max-height: ${props => {
        const isLimitedSizeGroup = LIMITED_SIZE_SYMBOLS.find(symbol => symbol === props.symbol);
      
        return isLimitedSizeGroup ? '300px' : 'calc(100% - 2rem)';
      }};
    }
    
    @media (max-width: 767px) {
      max-height: calc(60% - 2rem);
      height: 100%;
      top: 40%;
      width: calc(100% - 2rem);
    }
  `;

export const TooltipContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #4b535e;
    background: #fff;
    z-index: 10;
    font-size: 14px;
    font-weight: bold;
    width: 250px;
    height: auto;
    -webkit-box-shadow: 0px 5px 20px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 20px -12px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 20px -12px rgba(0,0,0,0.75);
  `;

