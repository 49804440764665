import {Icon, Style} from 'ol/style.js';
import CameraMarker from '../../assets/gfx/markers/camera.svg'
import CameraSelectedMarker from '../../assets/gfx/markers/camera-selected.svg'

export default {
  default: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: CameraMarker
    })
  }),
  hovered: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: CameraMarker,
      color: '#f8f8f8'
    })
  }),
  selected: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: CameraSelectedMarker
    })
  }),
}