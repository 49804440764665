export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        id: 1,
        name: 'Dworzec Główny PKP',
        spots: {
          public: {
            free: 42,
            total: 61
          },
          handicapped: {
            free: 2,
            total: 3
          }
        },
        fees: [
          { name: 'Do pół godziny', value: '1.00zł' },
          { name: 'Za pierwszą godzinę', value: '1.50zł' },
          { name: '2 godziny', value: '3.30zł' },
          { name: '3 godziny', value: '5.40zł' },
          { name: '4 godziny', value: '6.90zł' },
          { name: '5 godzin', value: '8.40zł' },
          { name: '6 godzin', value: '9.00zł' },
          { name: '7 godzin', value: '11.40zł' },
          { name: '8 godzin', value: '12.90zł' },
          { name: 'Każda następna godzina', value: '1.00zł' }
        ]
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              17.33166217803955,
              50.47161717843217
            ],
            [
              17.331608533859253,
              50.471538650857
            ],
            [
              17.33287990093231,
              50.4711118682765
            ],
            [
              17.33293890953064,
              50.471169910933796
            ],
            [
              17.33166217803955,
              50.47161717843217
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        name: 'Dworzec Główny PKP',
        spots: {
          public: {
            free: 42,
            total: 61
          },
          handicapped: {
            free: 2,
            total: 3
          }
        },
        fees: [
          { name: 'Do pół godziny', value: '1.00zł' },
          { name: 'Za pierwszą godzinę', value: '1.50zł' },
          { name: '2 godziny', value: '3.30zł' },
          { name: '3 godziny', value: '5.40zł' },
          { name: '4 godziny', value: '6.90zł' },
          { name: '5 godzin', value: '8.40zł' },
          { name: '6 godzin', value: '9.00zł' },
          { name: '7 godzin', value: '11.40zł' },
          { name: '8 godzin', value: '12.90zł' },
          { name: 'Każda następna godzina', value: '1.00zł' }
        ]
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              17.330814599990845,
              50.472081512732565
            ],
            [
              17.330760955810547,
              50.47202005698405
            ],
            [
              17.3319411277771,
              50.471603521471934
            ],
            [
              17.332016229629517,
              50.471678634704354
            ],
            [
              17.330814599990845,
              50.472081512732565
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        name: 'Dworzec Główny PKP',
        spots: {
          public: {
            free: 42,
            total: 61
          },
          handicapped: {
            free: 2,
            total: 3
          }
        },
        fees: [
          { name: 'Do pół godziny', value: '1.00zł' },
          { name: 'Za pierwszą godzinę', value: '1.50zł' },
          { name: '2 godziny', value: '3.30zł' },
          { name: '3 godziny', value: '5.40zł' },
          { name: '4 godziny', value: '6.90zł' },
          { name: '5 godzin', value: '8.40zł' },
          { name: '6 godzin', value: '9.00zł' },
          { name: '7 godzin', value: '11.40zł' },
          { name: '8 godzin', value: '12.90zł' },
          { name: 'Każda następna godzina', value: '1.00zł' }
        ]
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              17.33191967010498,
              50.47172984820349
            ],
            [
              17.332032322883606,
              50.471709362810486
            ],
            [
              17.332777976989746,
              50.47267558084955
            ],
            [
              17.33271896839142,
              50.472719964949405
            ],
            [
              17.33191967010498,
              50.47172984820349
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        name: 'Dworzec Główny PKP',
        spots: {
          public: {
            free: 42,
            total: 61
          },
          handicapped: {
            free: 2,
            total: 3
          }
        },
        fees: [
          { name: 'Do pół godziny', value: '1.00zł' },
          { name: 'Za pierwszą godzinę', value: '1.50zł' },
          { name: '2 godziny', value: '3.30zł' },
          { name: '3 godziny', value: '5.40zł' },
          { name: '4 godziny', value: '6.90zł' },
          { name: '5 godzin', value: '8.40zł' },
          { name: '6 godzin', value: '9.00zł' },
          { name: '7 godzin', value: '11.40zł' },
          { name: '8 godzin', value: '12.90zł' },
          { name: 'Każda następna godzina', value: '1.00zł' }
        ]
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              17.333756983280182,
              50.47143280913627
            ],
            [
              17.33372211456299,
              50.47138842382803
            ],
            [
              17.33472794294357,
              50.47094286130975
            ],
            [
              17.33520805835724,
              50.47079946098542
            ],
            [
              17.335261702537533,
              50.47082506821808
            ],
            [
              17.335141003131863,
              50.470889939812125
            ],
            [
              17.33476549386978,
              50.47098895417877
            ],
            [
              17.333756983280182,
              50.47143280913627
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        name: 'Dworzec Główny PKP',
        spots: {
          public: {
            free: 42,
            total: 61
          },
          handicapped: {
            free: 2,
            total: 3
          }
        },
        fees: [
          { name: 'Do pół godziny', value: '1.00zł' },
          { name: 'Za pierwszą godzinę', value: '1.50zł' },
          { name: '2 godziny', value: '3.30zł' },
          { name: '3 godziny', value: '5.40zł' },
          { name: '4 godziny', value: '6.90zł' },
          { name: '5 godzin', value: '8.40zł' },
          { name: '6 godzin', value: '9.00zł' },
          { name: '7 godzin', value: '11.40zł' },
          { name: '8 godzin', value: '12.90zł' },
          { name: 'Każda następna godzina', value: '1.00zł' }
        ]
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              17.33379989862442,
              50.47091554699578
            ],
            [
              17.3337784409523,
              50.47087286834863
            ],
            [
              17.33445167541504,
              50.47066801030603
            ],
            [
              17.334623336791992,
              50.47059289546801
            ],
            [
              17.33495593070984,
              50.47042559380897
            ],
            [
              17.335135638713833,
              50.47037779322622
            ],
            [
              17.335141003131863,
              50.4704375439471
            ],
            [
              17.334982752799988,
              50.470473394343394
            ],
            [
              17.334685027599335,
              50.47061508850074
            ],
            [
              17.33446776866913,
              50.470703860527564
            ],
            [
              17.33379989862442,
              50.47091554699578
            ]
          ]
        ]
      }
    }
  ]
}