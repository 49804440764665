import React, {Component} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import LAYER_SYMBOLS from '../../../constants/layer-symbols';
import Parking from './element-types/Parking/Parking';
import BusStop from './element-types/BusStop/BusStop';
import Sensor from './element-types/Sensor/Sensor';
import Event from './element-types/Event/Event';
import ParkingMeter from './element-types/ParkingMeter/ParkingMeter';
import Camera from "./element-types/Camera/Camera";
import VariableMessageSigns from './element-types/VariableMessageSigns/VariableMessageSigns';
import Vehicle from "./element-types/Vehicle/Vehicle";

const NON_SCROLLABLE_LAYERS = ['ANPR', 'CCTV', 'VMS'];

class OverlayContent extends Component {
  renderElementTypeContent(layer) {
    if (this.props.content) {
      switch(layer.toUpperCase()) {
        case LAYER_SYMBOLS.PARKINGS_POINT:
        case LAYER_SYMBOLS.PARKINGS_POLYGON:  return <Parking {...this.props.content} />;
        case LAYER_SYMBOLS.STOPS:             return <BusStop {...this.props.content} />;
        case LAYER_SYMBOLS.VEHICLES:          return <Vehicle {...this.props.content} />;
        case LAYER_SYMBOLS.AIRLY_MEASUREMENT: return <Sensor {...this.props.content} />;
        case LAYER_SYMBOLS.BREAKDOWN:
        case LAYER_SYMBOLS.CONSTRUCTION:
        case LAYER_SYMBOLS.WARNING:
        case LAYER_SYMBOLS.MASS_EVENT:        return <Event {...this.props.content} />;
        case LAYER_SYMBOLS.PARKING_ZONES:
        case LAYER_SYMBOLS.PARKING_METERS:    return <ParkingMeter {...this.props.content} />;
        case LAYER_SYMBOLS.ANPR:
        case LAYER_SYMBOLS.CCTV:              return <Camera {...this.props.content} />;
        case LAYER_SYMBOLS.VMS:               return <VariableMessageSigns {...this.props.content} />
        default: return null;
      }
    }
    return <div></div>
  }

  render() {
    const {layer} = this.props;
    const isScrollable = !NON_SCROLLABLE_LAYERS.find(symbol => symbol === layer);

    return (
      isScrollable ?
        (
          <PerfectScrollbar>
            {this.renderElementTypeContent(layer)}
          </PerfectScrollbar>
        ) :
        this.renderElementTypeContent(layer)
    )
  }
}

export default OverlayContent;