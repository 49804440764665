import {Icon, Style} from 'ol/style.js';
import MassEventsMarker from '../../assets/gfx/markers/mass-event.svg'
import MassEventsSelectedMarker from '../../assets/gfx/markers/mass-event-selected.svg'

export default {
  default: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: MassEventsMarker
    })
  }),
  hovered: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: MassEventsMarker,
      color: '#f8f8f8'
    })
  }),
  selected: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: MassEventsSelectedMarker
    })
  }),
}