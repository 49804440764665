import React from 'react';
import * as Styled from './OverlayHeader.styles';
import LAYER_SYMBOLS from '../../../constants/layer-symbols';

const processItemType = (layer) => {
  switch(layer.toUpperCase()) {
    case LAYER_SYMBOLS.PARKINGS_POINT:
    case LAYER_SYMBOLS.PARKINGS_POLYGON: return 'Parking';
    case LAYER_SYMBOLS.STOPS: return 'Przystanek';
    case LAYER_SYMBOLS.VEHICLES: return 'Linia';
    case LAYER_SYMBOLS.AIRLY_MEASUREMENT: return 'Czujnik powietrza';
    case LAYER_SYMBOLS.BREAKDOWN: return 'Awaria';
    case LAYER_SYMBOLS.CONSTRUCTION: return 'Roboty drogowe';
    case LAYER_SYMBOLS.WARNING: return 'Ostrzeżenie';
    case LAYER_SYMBOLS.MASS_EVENT: return 'Impreza masowa';
    case LAYER_SYMBOLS.PARKING_ZONES: return 'Strefa parkowania';
    case LAYER_SYMBOLS.PARKING_METERS: return 'Parkometr';
    case LAYER_SYMBOLS.ANPR:
    case LAYER_SYMBOLS.CCTV: return 'Kamera';
    case LAYER_SYMBOLS.VMS: return 'Tablica VMS';
    default: return '';
  }
};

const renderDirection = (layer, direction) => {
  if (layer === LAYER_SYMBOLS.VEHICLES) return (
    <Styled.HeaderItemSubname>
      <Styled.Icon>&rarr;</Styled.Icon> {direction}
    </Styled.HeaderItemSubname>
  );
};

const OverlayHeader = (props) => {
  return (
    <Styled.HeaderContainer>
      <Styled.HeaderItemType>{processItemType(props.layer)}</Styled.HeaderItemType>
      <Styled.HeaderItemName>{props.name}</Styled.HeaderItemName>
      {renderDirection(props.layer, props.subname)}
    </Styled.HeaderContainer>
  )
};

export default OverlayHeader;
