import React, {Component} from 'react';
import * as Styled from './Map.styles';
import LayersContext from '../../LayersContext';
import Overlay from './Overlay';

import {fromLonLat} from 'ol/proj';
import {defaults} from 'ol/interaction';

import {createTooltip, initializeInteractions, INTERACTION_TYPES} from './helpers/interactions';
import {setLayersVisibility, initializeLayers} from './helpers/layers';
import {initializeMap} from './helpers/map';
import {loadFeatures} from "./helpers/features";
import LAYER_SYMBOLS from "../../constants/layer-symbols";

class Map extends Component {
  static contextType = LayersContext;
  state = {
    contextSnapshot: undefined,
    selectedItem: undefined,
    zoom: 15
  };

  initialExtent = {
    boundingBox: [1908212.192625, 6502249.575878, 1957705.168440, 6541041.367733],
    center: fromLonLat([17.33437, 50.47379]),
    zoom: 15
  };
  tooltipContainer = React.createRef();
  tooltip;
  interactions;
  layers = [];
  vehiclesInterval;

  componentDidMount() {
    this.layers = initializeLayers();

    this.map = initializeMap({
      target: 'map',
      extent: this.initialExtent,
      layers: this.layers,
      interactions: defaults({
        altShiftDragRotate: false,
        pinchRotate: false,
      })
    });

    this.attachTooltip();
    this.attachMapInteractions();
    this.attachMapListeners();
  }

  componentDidUpdate(prevProps) {
    const selectedMenuItems = this.context.layers;
    const recentlySelected = this.context.selectedGroup;

    const layers = this.layers.filter(layer => layer.get('groupSymbol') === recentlySelected);

    if (JSON.stringify(this.context.layers) !== this.state.contextSnapshot) {
      this.setState({ contextSnapshot: JSON.stringify(this.context.layers) }, () => {

        if (selectedMenuItems[recentlySelected]) {
          const vehiclesLayer = layers.find(layer => layer.getProperties().symbol === LAYER_SYMBOLS.VEHICLES);

          if (vehiclesLayer) {
            this.vehiclesInterval = setInterval(async () => {
              loadFeatures({ groupSymbol: recentlySelected, layer: vehiclesLayer })
                .then(features => {
                  vehiclesLayer.getSource().clear();
                  vehiclesLayer.getSource().addFeatures(features);

                  const selectInteraction = this.interactions.find(item => item.getProperties().type === INTERACTION_TYPES.SELECT);
                  const selectedFeatures = [...selectInteraction.getFeatures().getArray()];

                  if (selectedFeatures.length > 0) {
                    const newFeature = vehiclesLayer.getSource().getFeatureById(selectedFeatures[0].getId())

                    if (newFeature && newFeature.get('layer') === 'VEHICLES') {
                      selectInteraction.dispatchEvent({
                        type: 'select',
                        selected: [newFeature],
                        deselected: [selectedFeatures[0]]
                      })

                      selectInteraction.getFeatures().clear()
                      selectInteraction.getFeatures().push(newFeature)
                    }
                  }
                });
            }, 5000);
          }

          layers.forEach(layer => {
            loadFeatures({ groupSymbol: recentlySelected, layer })
              .then(features => {
                layer.getSource().addFeatures(features);
              });
          });

          setLayersVisibility({
            layers: this.layers,
            menuItems: selectedMenuItems,
            zoom: this.map.getView().getZoom()
          });
        }

        else {
          layers.forEach(layer => layer.getSource().clear());
          this.clearSelectedItems(recentlySelected);

          if(recentlySelected === LAYER_SYMBOLS.VEHICLES && !selectedMenuItems.VEHICLES) {
            clearInterval(this.vehiclesInterval);
          }
        }
      });
    }
  }

  clearSelectedItems(groupSymbol) {
    const selectInteraction = this.interactions.find(item => item.getProperties().type === INTERACTION_TYPES.SELECT);
    const features = selectInteraction.getFeatures().getArray();
    if (features.length > 0) {
      const symbol = features[0].getProperties().symbol;
      if (symbol === groupSymbol) {

        selectInteraction.getFeatures().clear();
        this.tooltip.setPosition(undefined);
        this.tooltip.persist = false;
        this.setSelectedItem();
      }
    }
  }

  setSelectedItem = (selectedItem) => {
    this.setState({ selectedItem });
  };

  attachTooltip() {
    this.tooltip = createTooltip(this.tooltipContainer.current);

    this.map.addOverlay(this.tooltip);
  }

  attachMapInteractions() {
    this.interactions = initializeInteractions({
      map: this.map,
      layers: this.layers,
      tooltip: this.tooltip,
      selectCallback: this.setSelectedItem
    });

    this.interactions.forEach(interaction => this.map.addInteraction(interaction));
  }

  attachMapListeners() {
    this.map.on('moveend', () => {
      const zoom = this.map.getView().getZoom();

      if (this.state.zoom !== zoom) {
        this.setState({ zoom }, () => {
          setLayersVisibility({
            layers: this.layers,
            menuItems: this.context.layers,
            zoom: this.map.getView().getZoom()
          });
        });
      }
    });

    this.map.on('pointermove', e => {
      const pixel = this.map.getEventPixel(e.originalEvent);
      const hit = this.map.hasFeatureAtPixel(pixel);
      this.map.getViewport().style.cursor = hit ? 'pointer' : '';
    });
  }

  renderOverlay = () => this.state.selectedItem ? <Overlay element={this.state.selectedItem}/> : '';

  render() {
    const {selectedItem} = this.state;

    return (
      <Styled.Container>
        <Styled.Map id='map'>
          <Styled.OverlayContainer
            visible={selectedItem}
            symbol={selectedItem ? selectedItem.symbol : undefined}
          >
            {this.renderOverlay()}
          </Styled.OverlayContainer>
        </Styled.Map>
        <Styled.TooltipContainer id='popup-content' ref={this.tooltipContainer}/>
      </Styled.Container>
    )
  }
};

export default Map;
