import styled from 'styled-components';

export const MenuItem = styled.div`
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    flex-direction: row;
    display: flex;
    border-top: 1px solid #696969;
    
    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    
    @media (hover:hover) {
      :hover {
        background: ${props => props.active ? props.activeColor : `${props.activeColor}64`};
      }
    }
    
    @media (min-width: 1025px) {
      width: 60px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: 80px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 100%;
    }
    
    @media (max-width: 767px) {
      width: 100%;
    }
  `;

export const ImageContainer = styled.div`
    width: 100px;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    
    background: ${props => props.active ? props.activeColor : 'transparent'}
`;

export const LabelContainer = styled.div`
    display: flex;
    padding-left: 20px;
    align-items: center;
    width: 100%;
    
    background: ${props => props.active ? '#4B535E' : 'transparent'}
`;

