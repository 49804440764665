import React, {useState, useEffect} from 'react';
import * as Styled from './BusStop.styles';
import Departures from './Departures/Departures';
import Timetables from './Timetables/Timetables';
import StopsService from '../../../../../services/stops';

const BusStop = (props) => {
  const [selectedTab, setSelectedTab] = useState('departures');
  const [departures, setDepartures] = useState([]);
  const [timetable, setTimetable] = useState([]);

  useEffect(() => {
    const {code, id} = props.data;

    fetchDepartures(code)
    fetchTimetable(id)

    const interval = setInterval(() => {
      fetchDepartures(code);
    }, 15000);

    return () => clearInterval(interval);
  }, [props.data])

  const fetchDepartures = (code) => {
    StopsService.getStopDepartures(code)
      .then(departures => setDepartures(departures));
  }

  const fetchTimetable = (id) => {
    StopsService.getStopTimetable(id)
      .then(timetable => setTimetable(timetable));
  }

  const renderContent = () => {
    return selectedTab === 'departures' ?
      <Departures data={departures}/> :
      <Timetables data={timetable} stopId={props.data.id}/>
  }
  return (
    <>
      <Styled.TabsContainer>
        <Styled.Tabs>
          <Styled.Tab isActive={selectedTab === 'departures'}
                      onClick={() => setSelectedTab('departures')}>Odjazdy</Styled.Tab>
          <Styled.Tab isActive={selectedTab === 'timetables'}
                      onClick={() => setSelectedTab('timetables')}>Rozkłady</Styled.Tab>
        </Styled.Tabs>
      </Styled.TabsContainer>
      {renderContent()}
    </>
  )
}

export default BusStop;
