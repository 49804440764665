import PATHS from "../constants/paths";
import {responseMiddleware} from "./response-middleware";
import EmptyDataset from "../constants/mock-data/features/empty-dataset";

export default {
  createVisibilityMap,
  getLayersVisibility
}

function createVisibilityMap(layers) {
  const visibilityMap = {};

  layers.forEach(record => visibilityMap[record.layer] = record.enabled);

  return visibilityMap;
}

function getLayersVisibility() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/layers`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}
