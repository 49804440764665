import styled from 'styled-components';

export const MeasurementsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  `;

export const CAQIMessageItem = styled.div`
  width: 100%;
  min-height: 85px;
  display: grid;
  grid-template-columns: 80px 220px;
  grid-template-rows: 100%;
  color: #fff;
  background: ${props => {
    switch (props.level) {
      case 'VERY_LOW'   : return '#6BC926';
      case 'LOW'        : return '#D1CF1E';
      case 'MEDIUM'     : return '#EFBB0F';
      case 'HIGH'       : return '#EF7120';
      case 'VERY_HIGH'  : return '#EF2A36';
      case 'EXTREME'    : return '#B00057';
      case 'AIRMAGEDDON': return '#770078';
      default: return '#333';
    }
  }}; 
`;

export const IndexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CAQIIndex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 40%;
  padding: 0.5rem;
  height: 35px;
  width: 30px;
`;

export const CAQIValue = styled.span`
  font-size: 22px;
`;

export const CAQISymbol = styled.span`
  font-size: 12px;
`;

export const MessageContainer = styled.div`
  padding: 1rem 1rem 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MessageContent = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
  span {
    :first-of-type {
      margin-bottom: 4px;
    }
  }
`;

export const MeasurementItem = styled.div`
    width: 100%;
    height: 85px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 100%;
    
    border-top: 1px solid #f4f4f4;
   `;

export const MeasurementIconContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #f4f4f4;
  `;

export const MeasurementIcon = styled.img``;

export const MeasurementLabel = styled.span`
    color: #909cac;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
  `;

export const MeasurementDataContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4b535e;
    font-weight: bold;
    font-size: 20px;
    
    sup {
      font-size: 14px;
      position: relative;
      bottom: 3px;
      left: 1px;
    }
  `;

export const Standards = styled.span`
  font-size: 12px;
  font-weight: normal;
  position: relative;
  top: 3px;
`;

export const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4b535e;
    width: 100%;
  `;

export const PlotContainer = styled.div`
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid #f4f4f4;
  `;

export const PlotHeader = styled.p`
    font-size: 16px;
    text-align: center;
    color: #4b535e;
    font-weight: bold;
  `;

export const CrosshairDataContainer = styled.div`
    border-radius: 5px;
    padding: 5px;
    background: rgba(75, 83, 94, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

export const CrosshairDataRow = styled.p`
    margin: 0;
    font-size: 12px;
    width: 100%;
    text-align: center;
  `;

export const CrosshairHourRow = styled(CrosshairDataRow)`
  font-weight: bold;
`;