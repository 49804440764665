export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": { "number": '0001', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331852614879605,
          50.47167692758676
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0002', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33177751302719,
          50.4717025343442
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0003', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33169436454773,
          50.47172984820349
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331621944904327,
          50.47175204070255
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331538796424866,
          50.471779354533254
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33146369457245,
          50.47180666834818
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33138859272003,
          50.471835689259244
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3313108086586,
          50.47186641726329
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331230342388153,
          50.47189373102795
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33114182949066,
          50.47191592345006
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331061363220215,
          50.47194494429408
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.330986261367794,
          50.47197055090634
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33090043067932,
          50.47200127882275
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33082801103592,
          50.47203029961436
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3316890001297,
          50.47156596481098
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331761419773102,
          50.47154035797957
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331839203834534,
          50.47151987250446
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33191430568695,
          50.471499387020515
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.331992089748383,
          50.471470365902995
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332069873809814,
          50.47144475901983
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332142293453217,
          50.471419152122806
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33221471309662,
          50.47139866659518
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332279086112976,
          50.47137647393024
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332351505756378,
          50.471352574125554
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33242392539978,
          50.471326967178605
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": true, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332499027252197,
          50.47130477448001
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332568764686584,
          50.471282581771
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332635819911957,
          50.47125868191887
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332705557346344,
          50.471239903455164
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33276456594467,
          50.4712177107157
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33284503221512,
          50.471186982290014
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33287453651428,
          50.47114430388789
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "handicapped" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332000136375427,
          50.4717452122424
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332032322883606,
          50.471782768760995
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33206182718277,
          50.47182373947451
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332099378108975,
          50.471857881708644
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332128882408142,
          50.47189885235704
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332158386707306,
          50.47193640875358
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3321932554245,
          50.47197055090634
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332220077514645,
          50.472011521457134
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332252264022827,
          50.472052491972384
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332284450531006,
          50.47209516955471
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3323193192482,
          50.472134432896425
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332348823547363,
          50.47217540330519
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33238101005554,
          50.47221295948201
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332415878772736,
          50.472250515629035
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3324453830719,
          50.47228977884172
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332482933998108,
          50.47232904202183
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33251243829727,
          50.4723717193545
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332536578178406,
          50.472407568284176
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3325714468956,
          50.47245024554598
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332603633403778,
          50.47248438732768
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332635819911957,
          50.47252365034615
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332668006420135,
          50.47256462041762
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3326975107193,
          50.472609004621624
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.332729697227478,
          50.472651681701606
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33378380537033,
          50.47138842382803
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.333845496177673,
          50.471367938287095
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33389914035797,
          50.471344038478136
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3339581489563,
          50.47132013865706
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33401447534561,
          50.47129453169253
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33407348394394,
          50.471265510449285
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334127128124237,
          50.471239903455164
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33418881893158,
          50.47121941784987
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334239780902863,
          50.4711921036957
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334301471710205,
          50.47116820379784
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3343551158905,
          50.47114259675099
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33441412448883,
          50.4711169896903
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334459722042084,
          50.471098211170315
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33451873064041,
          50.47107260408558
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334575057029724,
          50.47104358270611
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334628701210022,
          50.47102309701576
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334685027599335,
          50.470999197032455
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334749400615692,
          50.47097529703709
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334821820259094,
          50.47095310417341
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334894239902493,
          50.47093432558834
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334963977336884,
          50.47091896128589
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335031032562256,
          50.47090359697846
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335103452205658,
          50.470877989788335
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335167825222015,
          50.47085067543691
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335224151611328,
          50.470830189662976
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.333824038505554,
          50.47087969693477
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.333885729312897,
          50.470862625467596
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33394205570221,
          50.47084726114186
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334009110927582,
          50.470830189662976
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334068119525906,
          50.47080970388014
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334132492542267,
          50.47079263238769
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33418881893158,
          50.47077385373888
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334250509738922,
          50.47075678223349
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334306836128235,
          50.47073629641887
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334363162517548,
          50.470720932052075
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334416806697845,
          50.47070727483294
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33446776866913,
          50.470685081843484
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334526777267456,
          50.4706645959978
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334580421447754,
          50.47063898867822
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33463406562805,
          50.470618502812556
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334687709808346,
          50.47059289546801
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334749400615692,
          50.470568995267236
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334800362586975,
          50.47053826641996
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33485400676727,
          50.47051607335114
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3349130153656,
          50.47048705163005
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334972023963928,
          50.47046485853722
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33503371477127,
          50.47044607975821
        ]
      }
    },
    {
      "type": "Feature",
      "properties": { "number": '0081', "parkingId": 1, "occupied": false, "parkingPlaceType": "public" },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335098087787628,
          50.47043071529711
        ]
      }
    }
  ]
}