import {Icon, Style} from 'ol/style.js';
import * as AirlyGradients from "../../../assets/gfx/airly-gradients";
import LAYER_SYMBOLS from "../../../constants/layer-symbols";

const getIconScale = (resolution) => {
  if (resolution < 1) return 1;
  if (resolution < 2) return 0.75;
  if (resolution < 3) return 0.5;

  return 2 / resolution
}

export const ParkingSpotsStylingFunction = ({feature, resolution, style}) => {
  const result = [style.default];
  const isHandicapped = feature.get('parkingPlaceType') === 'handicapped';

  if (isHandicapped) result.push(style.handicapped);

  feature.set('defaultStyle', result, true);
  return result;
};

export const ParkingStylingFunction = ({feature, resolution, style}) => {
  let result;
  const spots = feature.get('spots');

  if (spots.hasOwnProperty('public') && spots.public.hasOwnProperty('free') && spots.public.hasOwnProperty('total')) {
    const free = spots.public.free;
    const total = spots.public.total;

    const ratio = free === 0 ? 0 : free / total;

    if (style.marker) {
      const scale = getIconScale(resolution);

      style.marker.empty.getImage().setScale(scale);
      style.marker.loaded.getImage().setScale(scale);
      style.marker.full.getImage().setScale(scale);
    }

    if (ratio > 0.5) result = resolution > 1 ? [style.polygon.empty, style.marker.empty] : [style.polygon.empty];
    if (ratio <= 0.5 && ratio >= 0.15) result = resolution > 1 ? [style.polygon.loaded, style.marker.loaded] : [style.polygon.loaded];
    if (ratio < 0.15) result = resolution > 1 ? [style.polygon.full, style.marker.full] : [style.polygon.full];

    feature.set('defaultStyle', result, true);
    return result;
  }

  return style.default
};

export const AirlyStylingFunction = ({feature, resolution, style}) => {
  const HEATMAP_SCALE_MULTIPLIER = 15;
  const level = feature.get('level');
  let source;

  switch(level) {
    case 'VERY_LOW': source = AirlyGradients.AirlyGradientVeryLow; break;
    case 'LOW': source = AirlyGradients.AirlyGradientLow; break;
    case 'MEDIUM': source = AirlyGradients.AirlyGradientMedium; break;
    case 'HIGH': source = AirlyGradients.AirlyGradientHigh; break;
    case 'VERY_HIGH': source = AirlyGradients.AirlyGradientVeryHigh; break;
    case 'EXTREME': source = AirlyGradients.AirlyGradientExtreme; break;
    case 'AIRMAGEDDON': source = AirlyGradients.AirlyGradientAirmageddon; break;
    default:
      feature.set('defaultStyle', style.default, true);
      return style.default;
  }

  const gradientStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: source,
      scale: HEATMAP_SCALE_MULTIPLIER / resolution
    })
  });

  return gradientStyle;
};

export const DefaultStylingFunction = ({feature, resolution, style}) => {
  if (style.default.getImage()) {
    const scale = getIconScale(resolution);

    style.default.getImage().setScale(scale);
  }

  const result = style.default;


  feature.set('defaultStyle', result, true);
  return result;
};

export const HoverStylingFunction = (feature, resolution) => {
  const style = feature.get('defaultStyle');
  const layer = feature.get('layer');

  if (layer === LAYER_SYMBOLS.PARKINGS_POLYGON) return ParkingPolygonHoverStylingFunction({feature, resolution});

  if (Array.isArray(style)) return [...feature.get('defaultStyle'), feature.get('style').hovered];
  return [feature.get('defaultStyle'), feature.get('style').hovered];
};

const ParkingPolygonHoverStylingFunction = ({feature, resolution}) => {
  const style = feature.get('style');
  const hoverStyle = resolution > 1 ? [style.polygon.hovered, style.marker.hovered] : [style.polygon.hovered];

  return [
    ...feature.get('defaultStyle'),
    ...hoverStyle
    ]
};

export const SelectStylingFunction = (feature, resolution) => {
  const layer = feature.get('layer');

  if (layer === LAYER_SYMBOLS.PARKINGS_POLYGON) return ParkingPolygonSelectStylingFunction({feature, resolution});

  return [feature.get('style').default, feature.get('style').selected];
};

const ParkingPolygonSelectStylingFunction = ({feature, resolution}) => {
  const style = feature.get('style');
  const selectStyle = resolution > 1 ? [style.polygon.selected, style.marker.selected] : [style.polygon.selected];

  return [
    ...feature.get('defaultStyle'),
    ...selectStyle
  ]
};
