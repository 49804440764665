import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
`;

