import styled from 'styled-components';
import {Tooltip} from 'react-tippy';

export const MenuItem = styled.div`
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid #545c66;
    cursor: pointer;
    
    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    
    background: ${props => props.active ? props.activeColor : 'transparent'}
    
    @media (hover:hover) {
      :hover {
        background: ${props => props.active ? props.activeColor : `${props.activeColor}64`};
      }
    }
    
    @media (min-width: 1025px) {
      width: 60px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: 80px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 100%;
    }
    
    @media (max-width: 767px) {
      width: 100%;
    }
  `;

export const ImageContainer = styled(Tooltip)`
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  `;