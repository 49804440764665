import {Icon, Style} from 'ol/style.js';
import StopMarker from '../../assets/gfx/markers/bus-stop.svg'
import StopSelectedMarker from '../../assets/gfx/markers/bus-stop-selected.svg'

export default {
  default: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: StopMarker
    })
  }),
  hovered: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: StopMarker,
      color: '#f8f8f8'
    })
  }),
  selected: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: StopSelectedMarker
    })
  }),
}