import React from 'react';
import LayersContext from '../../../LayersContext';
import MenuMobileItem from "./MenuMobileItem";

import LAYERS from '../../../constants/layers';
import * as Styled from "./MenuMobile.styles";

import MenuCloseIcon from './../../../assets/gfx/icons/menu-close.svg';
import MenuOpenIcon from './../../../assets/gfx/icons/menu-open.svg';

const Consumer = LayersContext.Consumer;

const renderItems = (context, layersVisibility) => {
  return (
    LAYERS.map(layer =>
      <MenuMobileItem
        key={layer.symbol}
        iconPath={layer.menuIcon}
        activeColor={layer.activeColor}
        name={layer.name}
        symbol={layer.symbol}
        isVisible={layersVisibility[layer.symbol]}
        isActive={context.layers[layer.symbol]}
        handleClick={() => context.onLayerSelected(layer.symbol)}
      />
    )
  )
};

const MenuMobile = ({isMenuMobileOpen, onToggleMenu, layersVisibility}) => {
  return (
    <Consumer>
      {context =>
        <>
          <Styled.MenuContainer visible={isMenuMobileOpen}>
            <Styled.ToggleContainer visible={isMenuMobileOpen} onClick={() => onToggleMenu()}>
              <Styled.ImageContainer visible={isMenuMobileOpen}>
                <img src={isMenuMobileOpen ? MenuCloseIcon : MenuOpenIcon} alt="Toggle menu"/>
              </Styled.ImageContainer>
            </Styled.ToggleContainer>
            <Styled.MenuText visible={isMenuMobileOpen}>
              Wybierz rodzaj obiektów:
            </Styled.MenuText>
            <Styled.ItemsContainer visible={isMenuMobileOpen}>
              {renderItems(context, layersVisibility)}
            </Styled.ItemsContainer>
          </Styled.MenuContainer>
        </>
      }
    </Consumer>
  )
};

export default MenuMobile;
