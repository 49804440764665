import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: ${props => props.isVisible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 5;
  `;

export const Window = styled.div`
    margin: 1rem;
    padding: 1rem;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    z-index: 6;
    
    @media (min-width: 1025px) {
      width: 640px;
      height: 520px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: 640px;
      height: 520px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 640px;
      height: 520px;
    }
    
    @media (max-width: 767px) {
      width: 100%;
      min-height: 420px;
    }
  `;

export const Header = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  `;

export const HeaderParagraph = styled.p`
    margin-top: 0;
    font-size: 14px;
    color: #4B535E;
  `;

export const ButtonContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

export const Footer = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    margin-bottom: 1rem;
  `;

export const FooterImage = styled.img.attrs({
  alt: 'partners'
})`
    max-width: 100%;
  `;

export const PrivacyPolicy = styled.a`
  cursor: pointer;
  color: #4B535E;
  font-size: 12px;
  margin: 1rem 0;
`;

export const Background = styled.div`
    filter: brightness(80%);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(130deg, #909CAC 0%, #4B535E 100%);
    opacity: 0.8;
    z-index: 5;
  `;
