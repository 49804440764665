import React, {useState, useEffect} from 'react';
import * as Styled from "./LineDetails.styles";
import locale from 'date-fns/locale/pl';
import format from "date-fns/format";
import Timetable from './Timetable/Timetable'

const nextTimetable = (timetables, selected, setSelectedTimetable) => {
  const index = timetables.indexOf(selected);

  if (index < timetables.length - 1) setSelectedTimetable(timetables[index + 1]);
};

const previousTimetable = (timetables, selected, setSelectedTimetable) => {
  const index = timetables.indexOf(selected);

  if (index > 0) setSelectedTimetable(timetables[index - 1]);
};

const LineDetails = (props) => {
  const { line } = props;
  const [selectedTimetable, setSelectedTimetable] = useState(line.timetables.length > 0 ? line.timetables[0] : undefined);

  useEffect(() => {setSelectedTimetable(line.timetables.length > 0 ? line.timetables[0] : undefined)}, [line]);

  return (
    <Styled.Container>
      <Styled.LineMetadata>
        <Styled.LineInfo>
          <Styled.LineNumber>{line.number}</Styled.LineNumber>
          <Styled.LineDirection>{selectedTimetable ? selectedTimetable.direction : ''}</Styled.LineDirection>
        </Styled.LineInfo>
        <Styled.DatePickerContainer>
          <Styled.DatePickerArrow
            isActive={line.timetables.indexOf(selectedTimetable) > 0}
            onClick={() => previousTimetable(line.timetables, selectedTimetable, setSelectedTimetable)}
          >
            <span>{'<'}</span>
          </Styled.DatePickerArrow>
          <Styled.DatePickerData>
            <Styled.DatePickerWeekday>{format(selectedTimetable.date, 'dddd', { locale })}</Styled.DatePickerWeekday>
            <Styled.DatePickerDate>{format(selectedTimetable.date, 'DD.MM.YYYY')}</Styled.DatePickerDate>
          </Styled.DatePickerData>
          <Styled.DatePickerArrow
            isActive={line.timetables.indexOf(selectedTimetable) < line.timetables.length - 1}
            onClick={() => nextTimetable(line.timetables, selectedTimetable, setSelectedTimetable)}
          >
            <span>{'>'}</span>
          </Styled.DatePickerArrow>
        </Styled.DatePickerContainer>
      </Styled.LineMetadata>
      <Timetable timetable={selectedTimetable}/>
    </Styled.Container>
  );
};

export default LineDetails;
