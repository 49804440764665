import React from 'react';
import * as Styled from './MenuItem.styles';

const MenuItem = (props) => {
  return (
    props.isVisible ? (
      <Styled.MenuItem
        activeColor={props.activeColor}
        active={props.isActive}
        onClick={props.handleClick}
      >
        <Styled.ImageContainer title={props.name}>
          <img src={props.iconPath} alt={props.name}/>
        </Styled.ImageContainer>
      </Styled.MenuItem>
      ) : null
  )
};

export default MenuItem;
