import { responseMiddleware } from './response-middleware';
import PATHS from '../constants/paths';
import EmptyDataset from "../constants/mock-data/features/empty-dataset";

export default {
  getVMS,
  getImage
}

function getVMS() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/board/vms`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}

function getImage({id, width = 400, height = 300, signal}) {
  const requestOptions = {
    method: 'GET',
    signal
  };

  const params = new URLSearchParams({
    width,
    height
  }).toString()

  return fetch(`${PATHS.PUBLIC}/board/vms/image/${id}?${params}`, requestOptions)
    .then(responseMiddleware)
    .catch(error => {
      if (error.name === 'AbortError') return;
    })
}
