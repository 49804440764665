import { responseMiddleware } from './response-middleware';
import PATHS from '../constants/paths';
import EmptyDataset from '../constants/mock-data/features/empty-dataset';

export default {
  getANPR,
  getCCTV,
  getCameraImage
}

function getANPR() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/camera/anpr`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}

function getCCTV() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${PATHS.PUBLIC}/camera/cctv`, requestOptions)
    .then(responseMiddleware)
    .catch(() => EmptyDataset)
}

function getCameraImage({cameraId, signal}) {
  const requestOptions = {
    method: 'GET',
    signal
  };

  return fetch(`${PATHS.PUBLIC}/camera/image/${cameraId}`, {...requestOptions})
    .then(responseMiddleware)
    .catch(error => {
      if (error.name === 'AbortError') return;
    })
}

function getCameraImageThumbnail({cameraId, width = 300, height = 200, signal}) {
  const requestOptions = {
    method: 'GET',
    signal
  };

  const params = new URLSearchParams({
    width,
    height
  }).toString()

  return fetch(`${PATHS.PUBLIC}/camera/image/${cameraId}?${params}`, {...requestOptions})
    .then(responseMiddleware)
    .catch(error => {
      if (error.name === 'AbortError') return;
    })
}
