import styled from "styled-components";

export const Container = styled.div``;

export const LineMetadata = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LineInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LineNumber = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #4b535e;
`;

export const LineDirection = styled.div`
  font-size: 11px;
  color: #909cac;
`;

export const DatePickerContainer = styled.div`
  display: grid;  
  grid-template-columns: 15px 90px 15px;
`;

export const DatePickerData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DatePickerWeekday = styled.div`
  font-size: 12px;
  color: #909cac;
  margin-bottom: 3px;
`;

export const DatePickerDate = styled.div`
  font-size: 11px;
  color: #4B535E;
  font-weight: bold;
`;

export const DatePickerArrow = styled.div`
  font-size: 18px;
  font-weight: bold;
  user-select: none;
  
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  color: ${props => props.isActive ? '#4b535e' : '#eeeeee'};
  
  :hover {
    color: ${props => props.isActive ? '#4b535e60' : '#eeeeee'};
  }
`;