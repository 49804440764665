import {
  ParkingIcon,
  ParkingMeterIcon,
  VehicleIcon,
  StopIcon,
  CameraIcon,
  WarningIcon,
  SensorIcon,
  VMSIcon
} from './icons';
import {
  AirlyMeasurementStyle,
  AirlyCloudStyle,
  CameraStyle,
  MassEventsStyle,
  ConstructionStyle,
  WarningStyle,
  ParkingMeterStyle,
  ParkingZoneStyle,
  ParkingStyle,
  ParkingSpotStyle,
  StopStyle,
  VehicleStyle,
  VMSStyle, BreakdownStyle,
} from './map-styles/';
import * as Data from './mock-data/features/';
import LAYER_SYMBOLS from './layer-symbols';
import GROUP_SYMBOLS from './group-symbols';

export default [
  {
    name: 'Parkingi',
    symbol: GROUP_SYMBOLS.PARKINGS,
    activeColor: '#8596D2',
    menuIcon: ParkingIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.PARKINGS_POINT,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: ParkingSpotStyle,
        data: Data.ParkingsPoint,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: true,
          threshold: {
            condition: 'above',
            level: 19
          }
        }
      },
      {
        mapSymbol: LAYER_SYMBOLS.PARKINGS_POLYGON,
        geometryType: 'Polygon',
        interactive: true,
        style: ParkingStyle,
        data: Data.ParkingsPolygon,
        zIndex: 4,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
  {
    name: 'Parkomaty',
    symbol: GROUP_SYMBOLS.PARKING_METERS,
    activeColor: '#6F86AF',
    menuIcon: ParkingMeterIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.PARKING_METERS,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: ParkingMeterStyle,
        data: Data.ParkingMeters,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      },
      {
        mapSymbol: LAYER_SYMBOLS.PARKING_ZONES,
        geometryType: 'Polygon',
        interactive: true,
        markerIcons: {},
        style: ParkingZoneStyle,
        data: Data.ParkingZones,
        zIndex: 3,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
  {
    name: 'Pojazdy',
    symbol: GROUP_SYMBOLS.VEHICLES,
    activeColor: '#65B2B2',
    menuIcon: VehicleIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.VEHICLES,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: VehicleStyle,
        data: Data.Vehicles,
        zIndex: 6,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
  {
    name: 'Przystanki',
    symbol: GROUP_SYMBOLS.STOPS,
    activeColor: '#85D2A4',
    menuIcon: StopIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.STOPS,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: StopStyle,
        data: Data.Stops,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
  {
    name: 'Tablice',
    symbol: GROUP_SYMBOLS.VMS,
    activeColor: '#AFD281',
    menuIcon: VMSIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.VMS,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: VMSStyle,
        data: Data.VMS,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
  {
    name: 'Kamery',
    symbol: GROUP_SYMBOLS.CAMERAS,
    activeColor: '#DBC374',
    menuIcon: CameraIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.ANPR,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: CameraStyle,
        data: Data.Cameras,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      },
      {
        mapSymbol: LAYER_SYMBOLS.CCTV,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: CameraStyle,
        data: Data.Cameras,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
  {
    name: 'Czujniki',
    symbol: GROUP_SYMBOLS.SENSORS,
    activeColor: '#DC9F7C',
    menuIcon: SensorIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.AIRLY_MEASUREMENT,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: AirlyMeasurementStyle,
        data: Data.AirlyHeatmap,
        zIndex: 3,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      },
      {
        mapSymbol: LAYER_SYMBOLS.AIRLY_CLOUD,
        geometryType: 'Point',
        interactive: false,
        markerIcons: {},
        style: AirlyCloudStyle,
        data: Data.AirlyHeatmap,
        zIndex: 1,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
  {
    name: 'Zdarzenia',
    symbol: GROUP_SYMBOLS.EVENTS,
    activeColor: '#C4818A',
    menuIcon: WarningIcon,
    mapLayers: [
      {
        mapSymbol: LAYER_SYMBOLS.MASS_EVENT,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: MassEventsStyle,
        data: Data.MassEvents,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      },
      {
        mapSymbol: LAYER_SYMBOLS.CONSTRUCTION,
        geometryType: 'Polygon',
        interactive: true,
        markerIcons: {},
        style: ConstructionStyle,
        data: Data.Constructions,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      },
      {
        mapSymbol: LAYER_SYMBOLS.WARNING,
        geometryType: 'Point',
        interactive: true,
        markerIcons: {},
        style: WarningStyle,
        data: Data.Warnings,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      },
      {
        mapSymbol: LAYER_SYMBOLS.BREAKDOWN,
        geometryType: 'Polygon',
        interactive: true,
        markerIcons: {},
        style: BreakdownStyle,
        data: Data.Breakdowns,
        zIndex: 5,
        visibilityBreakpoint: {
          hasBreakpoint: false,
        }
      }
    ]
  },
]
