import styled from "styled-components";

export const MenuContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => props.visible ? '100%' : '60px'};
    cursor: pointer;
    background: ${props => props.visible ? 'linear-gradient(#909CAC, #4B535E)': 'transparent'};
    
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `;

export const ToggleContainer = styled.div`
    background-color: ${props => props.visible ? 'transparent' : '#4b535e'};
    height: 60px;
    width: ${props => props.visible ? '60px' : '100%'};
  `;

export const ImageContainer = styled.div`
    background-color: ${props => props.visible ? 'transparent' : '#555e69'};
    width: 60px;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
`;

export const MenuText = styled.div`
    display: ${props => props.visible ? 'flex' : 'none'};
    font-size: 16px;
    height: 80px;
    width: 100%;
    justify-content: center;
    align-items: center;
  `;

export const ItemsContainer = styled.div`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;
    height: calc(100% - 140px);
    width: 100%;
    justify-content: center;
    align-items: center;
  `;
