import * as Styled from "./Legend.styles";
import React from "react";

const Legend = ({data}) => {
  return data.length > 0 ? (
    <Styled.LegendContainer>
      <Styled.LegendHeader>Legenda</Styled.LegendHeader>
      {
        data.map(({footnote, footnoteDescription}) => {
          return (
            <Styled.LegendRow key={footnote}>
              <Styled.FootnoteSymbol>
                {footnote}
              </Styled.FootnoteSymbol>
              <Styled.FootnoteDescription>
                {footnoteDescription}
              </Styled.FootnoteDescription>
            </Styled.LegendRow>
          )
        })
      }
    </Styled.LegendContainer>
  ) : null;
};

export default Legend;
