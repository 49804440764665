export default {
  "crs": {
    "properties": {
      "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
    },
    "type": "name"
  },
  "features": [
    {
      "geometry": {
        "coordinates": [
          17.332373,
          50.471786,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 6389,
        "name": "Bielawska",
        "advice": "The air is great!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 11.24,
        "PM1": 5.5,
        "PM25": 6.74,
        "PM10": 8.73,
        "PRESSURE": 1024.27,
        "HUMIDITY": 74.37,
        "TEMPERATURE": 19.98
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.328405,
          50.473709,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 6394,
        "name": "Bohaterów Warszawy",
        "advice": "Breathe to fill your lungs!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 7.83,
        "PM1": 3.67,
        "PM25": 4.7,
        "PM10": 6.1,
        "PRESSURE": 1025.81,
        "HUMIDITY": 73.09,
        "TEMPERATURE": 23.3
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.335778,
          50.476507,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 8885,
        "name": "Kolejowa",
        "advice": "Catch your breath!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 11.98,
        "PM1": 5.48,
        "PM25": 7.19,
        "PM10": 9.56,
        "HUMIDITY": 77.03,
        "TEMPERATURE": 20.07
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.335778,
          50.476507,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 297,
        "name": "Kornela Ujejskiego",
        "advice": "Breathe deeply!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 9.98,
        "PM1": 4.82,
        "PM25": 5.99,
        "PM10": 7.96,
        "PRESSURE": 1023.8,
        "HUMIDITY": 72.86,
        "TEMPERATURE": 19.39
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.343056,
          50.466221,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 7934,
        "name": "Piłsudskiego 7",
        "advice": "Zero dust - zero worries!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 12.43,
        "PM1": 5.44,
        "PM25": 7.46,
        "PM10": 9.93,
        "PRESSURE": 1025.51,
        "HUMIDITY": 74.31,
        "TEMPERATURE": 19.49
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.337839,
          50.483246,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 8887,
        "name": "Szkolna",
        "advice": "Great air!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 7.77,
        "PM1": 3.76,
        "PM25": 4.66,
        "PM10": 6.06,
        "PRESSURE": 1024.27,
        "HUMIDITY": 72.92,
        "TEMPERATURE": 22.65
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.333144,
          50.455921,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 6390,
        "name": "Rodziewiczówny",
        "advice": "Catch your breath!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 9.57,
        "PM1": 4.59,
        "PM25": 5.74,
        "PM10": 7.57,
        "PRESSURE": 1023.42,
        "HUMIDITY": 79.38,
        "TEMPERATURE": 20.95
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.356635,
          50.464948,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 7936,
        "name": "Reymonta",
        "advice": "Breathe as much as you can!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 8.65,
        "PM1": 3.93,
        "PM25": 5.19,
        "PM10": 6.79,
        "PRESSURE": 1025.36,
        "HUMIDITY": 75.53,
        "TEMPERATURE": 18.96
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.349436,
          50.458286,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 380,
        "name": "Krawiecka",
        "advice": "Catch your breath!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 8.72,
        "PM1": 4.35,
        "PM25": 5.23,
        "PM10": 6.8,
        "PRESSURE": 1023.81,
        "HUMIDITY": 71.73,
        "TEMPERATURE": 23.42
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.348638,
          50.488331,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 2711,
        "name": "Zygmunta Kaczkowskiego",
        "advice": "Enjoy life!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 8.34,
        "PM1": 4.11,
        "PM25": 5.01,
        "PM10": 6.5,
        "PRESSURE": 1025.67,
        "HUMIDITY": 75.31,
        "TEMPERATURE": 22.75
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.306521,
          50.434188,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 6393,
        "name": "Krótka",
        "advice": "It couldn't be better ;)",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 8.55,
        "PM1": 4.2,
        "PM25": 5.13,
        "PM10": 6.64,
        "PRESSURE": 1026.27,
        "HUMIDITY": 68.05,
        "TEMPERATURE": 21.26
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.2546,
          50.486191,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 6395,
        "name": "Goświnowice, Kolejowa",
        "advice": "It couldn't be better ;)",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 8.21,
        "PM1": 3.6,
        "PM25": 4.93,
        "PM10": 6.52,
        "PRESSURE": 1026.09,
        "HUMIDITY": 79.11,
        "TEMPERATURE": 19.89
      },
      "type": "Feature"
    },
    {
      "geometry": {
        "coordinates": [
          17.176387,
          50.463159,
        ],
        "type": "Point"
      },
      "properties": {
        "id": 2653,
        "name": "Goświnowice, Kolejowa",
        "advice": "Dear me, how wonderful!",
        "color": "#6BC926",
        "description": "Great air here today!",
        "level": "VERY_LOW",
        "CAQI": 8.42,
        "PM1": 4.07,
        "PM25": 5.05,
        "PM10": 6.54,
        "PRESSURE": 1025.62,
        "HUMIDITY": 79.75,
        "TEMPERATURE": 21.25
      },
      "type": "Feature"
    }
  ],
  "name": "Airly - gęstość",
  "type": "FeatureCollection"
}