export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "Kamera 1"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.336318492889404,
          50.47083531110729
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Kamera 2"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33065366744995,
          50.47445432637729
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Kamera 3"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335181236267086,
          50.47662560256472
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Kamera 4"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3384428024292,
          50.47846906066513
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Kamera 5"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.34163999557495,
          50.47464551193242
        ]
      }
    }
  ]
}