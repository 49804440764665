import React from 'react';
import * as Styled from './Button.styles';

export default ({ text, onClick, disabled, width, color }) => (
  <Styled.Button
    disabled={disabled}
    onClick={onClick}
    color={color}
    style={{width: width ? width : '100%'}}
  >
    {text}
  </Styled.Button>
);
