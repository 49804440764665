import {Icon, Style, Fill, Stroke} from 'ol/style.js';
import ConstructionMarker from '../../assets/gfx/markers/construction.svg'
import ConstructionSelectedMarker from '../../assets/gfx/markers/construction-selected.svg'

export default {
  default: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: ConstructionMarker
    }),
    stroke: new Stroke({
      color: '#C4818A',
      width: 1,
      lineDash: [3, 3]
    }),
    fill: new Fill({
      color: 'rgba(196, 129, 138, 0.3)'
    })
  }),
  hovered: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: ConstructionMarker,
      color: '#f8f8f8'
    }),
    stroke: new Stroke({
      color: '#C4818A',
      width: 1,
      lineDash: [3, 3]
    }),
    fill: new Fill({
      color: 'rgba(196, 129, 138, 0.3)'
    })
  }),
  selected: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: ConstructionSelectedMarker
    }),
    stroke: new Stroke({
      color: '#C4818A',
      width: 1,
      lineDash: [3, 3]
    }),
    fill: new Fill({
      color: 'rgba(196, 129, 138, 0.3)'
    })
  }),
}