import React, {Component} from 'react';
import * as Styled from './Camera.styles';
import FullscreenIcon from '../../../../../assets/gfx/icons/fullscreen.svg';
import CameraService from '../../../../../services/cameras';

const IMAGE_SIZE = {
  width: 1600,
  height: 1200
};

class Camera extends Component {
  interval = undefined;
  state = {
    isFullscreen: false,
    image: undefined
  };

  componentDidMount() {
    this.downloadImage();
    this.interval = setInterval(() => this.downloadImage(), 5000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.controller.abort();
      clearInterval(this.interval);

      this.setState({image: undefined});

      this.downloadImage();
      this.interval = setInterval(() => this.downloadImage(), 5000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.controller.abort();
  }

  downloadImage = () => {
    const cameraId = this.props.id;

    this.controller = new AbortController();
    const signal = this.controller.signal;

    CameraService.getCameraImage({cameraId, width: IMAGE_SIZE.width, height: IMAGE_SIZE.height, signal})
      .then(image => this.setState({image}));
  };

  setFullscreenOn = () => this.setState({isFullscreen: true});
  setFullscreenOff = () => this.setState({isFullscreen: false});

  renderFullscreenImage() {
    return this.state.isFullscreen ?
      <Styled.FullscreenImageContainer>
        <Styled.FullscreenImage
          src={`data:image/jpg;base64,${this.state.image}`}
          onClick={this.setFullscreenOff}
        />
      </Styled.FullscreenImageContainer> :
      '';
  }

  renderLoading() {
    return (
      <Styled.LoadingContainer>Wczytywanie...</Styled.LoadingContainer>
    );
  }

  render() {
    return (
      <>
        {
          this.state.image ? (
              <>
                <Styled.Image src={`data:image/jpg;base64,${this.state.image}`}/>
                <Styled.FullscreenButtonContainer onClick={this.setFullscreenOn}>
                  <img alt="fullscreen-camera" src={FullscreenIcon}/>
                </Styled.FullscreenButtonContainer>
                {this.renderFullscreenImage()}
              </>
            ) :
            this.renderLoading()
        }
      </>
    )
  }
}

export default Camera;
