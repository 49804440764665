import React, {Component} from 'react';
import * as Styled from './Parking.styles';
import {toLonLat} from 'ol/proj';

class Parking extends Component {
  renderPublicSpotCount() {
    const spots = this.props.spots;

    return spots.hasOwnProperty('public') && spots.public.hasOwnProperty('total') ?
      <span>{spots.public.total}</span>      :
      <span>0</span>
  }

  renderHandicappedSpotCount() {
    const spots = this.props.spots;

    return spots.hasOwnProperty('handicapped') && spots.handicapped.hasOwnProperty('total') ?
      <span>{spots.handicapped.total}</span>           :
      <span>0</span>
  }

  renderSpots() {
    return (
      <>
        <Styled.SpotsIndicatorRow>
          <Styled.SpotsRowLabel>
            ogólnodostępne
          </Styled.SpotsRowLabel>
          <Styled.SpotsRowValue>
            {this.renderPublicSpotCount()}
          </Styled.SpotsRowValue>
        </Styled.SpotsIndicatorRow>
        <Styled.SpotsIndicatorRow>
          <Styled.SpotsRowLabel>
            dla niepełnosprawnych
          </Styled.SpotsRowLabel>
          <Styled.SpotsRowValue>
            {this.renderHandicappedSpotCount()}
          </Styled.SpotsRowValue>
        </Styled.SpotsIndicatorRow>
      </>
    )
  }

  renderFees() {
    return this.props.fees.map((fee, index) => (
      <Styled.FeesRow key={index}>
        <Styled.FeeName>{fee.name}</Styled.FeeName>
        <Styled.FeeValue>{fee.value}</Styled.FeeValue>
      </Styled.FeesRow>
    ))
  }

  navigateTo = () => {
    if (this.props.center) {
      const destination = toLonLat(this.props.center);
      const url = `https://www.google.com/maps/dir/?api=1&destination=${destination[1]},${destination[0]}`;
      const anchor = document.createElement("a");

      anchor.href = url;
      anchor.target = '_blank';

      document.body.appendChild(anchor);
      anchor.click();
    }
  };

  render() {
    return (
      <>
        <Styled.SpotsContainer>
          <Styled.SpotsHeader>
            Miejsca postojowe
          </Styled.SpotsHeader>
          <Styled.SpotsIndicators>
            {this.renderSpots()}
          </Styled.SpotsIndicators>
          <Styled.SpotsButtonContainer>
            <Styled.SpotsButton onClick={this.navigateTo}>
              Nawiguj
            </Styled.SpotsButton>
          </Styled.SpotsButtonContainer>
        </Styled.SpotsContainer>
        <Styled.FeesContainer>
          <Styled.FeesHeader>
            Opłaty
          </Styled.FeesHeader>
          <Styled.FeesTable>
            {this.renderFees()}
          </Styled.FeesTable>
        </Styled.FeesContainer>
      </>
    )
  }
}

export default Parking;
