export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 1"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3297256231308,
          50.47298968281405
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 2"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.334752082824707,
          50.470794339537214
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 3"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.338464260101315,
          50.471996157504755
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 4"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.34069585800171,
          50.4743450771415
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 5"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33790636062622,
          50.47495960080872
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 6"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.335481643676758,
          50.4742767962409
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 7"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33346462249756,
          50.47337547911036
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 8"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.3323917388916,
          50.474809384649994
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 1"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.333507537841797,
          50.47635249154647
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Przystanek 1"
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.33571767807007,
          50.47754051297857
        ]
      }
    }
  ]
}