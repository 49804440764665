import React, {Component} from 'react';
import * as Styled from './SplashScreen.styles';
import logo from '../../assets/gfx/nysa_logo.png'
import UEFooter from '../../assets/gfx/img_ue_footer.svg';
import Button from '../common/Button';

class SplashScreen extends Component {
  state = {
    isVisible: true
  };

  hideWindow = () => this.setState({ isVisible: false });

  render() {
    return (
      <Styled.Container isVisible={this.state.isVisible}>
        <Styled.Window>
          <Styled.Header>
            <img src={logo} alt="Logo"/>
            <Styled.HeaderParagraph>Witamy w systemie ITS miasta Nysa.</Styled.HeaderParagraph>
          </Styled.Header>
          <Styled.ButtonContainer>
            <Button
              onClick={this.hideWindow}
              text={'Start'}
              width={'220px'}
              color={'#4b535e'}
            />
          </Styled.ButtonContainer>
          <Styled.Footer>
            <Styled.FooterImage src={UEFooter}/>
          </Styled.Footer>
          <Styled.PrivacyPolicy href={'/policy'} target="_blank">
            Polityka prywatności
          </Styled.PrivacyPolicy>
        </Styled.Window>
        <Styled.Background/>
      </Styled.Container>
    )
  }
}

export default SplashScreen;
