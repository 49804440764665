import {Icon, Style} from 'ol/style.js';
import VehiclesMarker from '../../assets/gfx/markers/vehicle.svg'
import VehiclesSelectedMarker from '../../assets/gfx/markers/vehicle-selected.svg'

export default {
  default: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: VehiclesMarker
    })
  }),
  hovered: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: VehiclesMarker,
      color: '#f8f8f8'
    })
  }),
  selected: new Style({
    image: new Icon({
      anchor: [0.5, 60],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixel',
      src: VehiclesSelectedMarker
    })
  }),
}